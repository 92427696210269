import React, { Component } from "react";
import axios from "axios";
import "./Pers.css";
import { Nav, Navbar, Accordion, Card } from "react-bootstrap";
import { MdFormatLineSpacing } from "react-icons/md";
import { FaPen, FaPaperPlane, FaSave } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import ContextAwareToggle from "../Profile/ContextAwareToggle";
import { Link } from "react-router-dom";
import Pdf from "../Pdf/Pdf";
import Decision from "../Decision/Decision";
import PersLetter from "../PersLetter/PersLetter";
import Comments from "../Comments/Comments";
import { useSelector, useDispatch } from "react-redux";

import LenderAndBuilder from "../LenderAndBuilder/lender-and-builder";
import PersDecisions from "../PersDecisions/PersDecisions";
import DropzoneModal from "../Dropzone/DropzoneModal";
import GetAppIcon from "@material-ui/icons/GetApp";

import $ from "jquery";
import Pers1029 from "../Pers-1029";
import PersAdditionalInfo from "../Pers-Additonal-info";
import Pers1071 from "../Pers-1071/pers1071";
import Pers1026 from "../Pers-1026/pers1026";
import PersStatus from "./PersStatus";
import PersDropdown from "./PersDropdown";
import {
  isReadonlyRole,
  isProjectStandardRole,
} from "../../redux/actions/userAction";
import HomeIcon from "@material-ui/icons/Home";
import RemoveIcon from "@material-ui/icons/Remove";
import ApartmentBlackIcon from "../../images/apartment_black_24dp@2x.svg";
import NewcondoIcon from "../../images/newcondo-image.png";
import EstablishedIcon from "../../images/established-image.png";
import Group17Icon from "../../images/Group 17.svg";
import Group18Icon from "../../images/Group 18.svg";
import Group33Icon from "../../images/Group 33.svg";
import Group34Icon from "../../images/Group-34.svg";

class Pers extends Component {
  constructor(props) {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    super(props);
    this.state = {
      pers: [],
      project: [],
      lender: {},
      budget: {},
      pers1026: {},
      pers1029: {},
      pers1051: {},
      pers1054: {},
      pers1071: {},
      pers1081: {},
      pers1026list: [],
      persId: "",
      projectId: "",
      phaseId: null,
      userName: passData && passData.user,
      comments: "",
      projectName: "",
      projectType: "",
      getDecision: [],
      getHeaderDecision: [],
      getLetter: [],

      isEditLender: false,

      collapseKey: "1",
      disablePostButton: false,
      apiErr: "",
      internalErr: false,
      persProjectType: "",
    };
    this.collapseAll = this.collapseAll.bind(this);

    //this.CPMId = React.createRef();
    this.lenderName = React.createRef();
    this.lenderSSN = React.createRef();
    this.requesterName = React.createRef();
    this.requesterEmail = React.createRef();
    //this.reviewedDate = React.createRef();
    this.receivedDate = React.createRef();
  }

  async componentDidMount() {
    let role = sessionStorage.getItem("role");
    if (role) {
      this.init();
    } else {
      this.getUserDetails();
    }
  }

  async getUserDetails() {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/translatedRoleMap/`)
      .then((response) => {
        if (response.status == 200) {
          sessionStorage.setItem("roleData", JSON.stringify(response.data));
          if (!sessionStorage.getItem("role")) {
            sessionStorage.setItem("role", response.data.selectedRole);
          }
          const passData = JSON.parse(sessionStorage.getItem("passData"));
          this.init(passData);
        }
      })
      .catch((error) => {
        throw Error(error);
      });
  }
  
  async init() {
    this.findProjectPersDetailsByPersId();
  }

  async getCommentsByProjectAndPers(){
    if (isProjectStandardRole() || isReadonlyRole()) {
      try {
        let projectId = this.state.projectId;
        let persId = this.state.persId;
        const { data: getComments } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/pers/getCommentsByProjectAndPers/` +
          projectId +
          `/` +
          persId
        );
        this.setState({
          getComments,
        });
        console.log(this.state.getComments);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async listAllDocsByProjectAndPersId() {
    try {
      let projectId = this.state.projectId;
      let persId = this.state.persId;

      const { data: getPdf } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/docStorage/listAllDocsByProjectAndPersId/` +
        projectId +
        `/` +
        persId
      );
      this.setState({
        getPdf,
      });
      console.log(this.state.getPdf);
    } catch (err) {
      console.log(err);
    }
  }

  async getPersLetterDetailsHistory() {
    try {
      let id = this.state.persId;
      const { data: getLetter } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/decision/getPersLetterDetailsHistory/` +
        id
      );
      this.setState({
        getLetter,
      });
      console.log("Letter Attributes", this.state.getLetter);
    } catch (err) {
      console.log(err);
    }
  }

  async getPersDecisionProfile() {
    try {
      let id = this.state.persId;
      const { data: getHeaderDecision } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/decision/getPersDecisionProfile/` + id
      );
      this.setState({
        getHeaderDecision,
      });
      console.log("Decision Header Attributes", this.state.getHeaderDecision);
    } catch (err) {
      console.log(err);
    }
  }
  async getPersDecisionHistory() {
    try {
      let id = this.state.persId;
      const { data: getDecision } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/decision/getPersDecisionHistory/` + id
      );
      this.setState({
        getDecision,
      });
      console.log("Decision Attributes", this.state.getDecision);
    } catch (err) {
      console.log(err);
    }
  }
  async getProjectDetailsByProjectId() {
    try {
      let id = this.state.projectId;
      const { data: project } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/condoMgr/getProjectDetailsByProjectId/` +
        id
      );
      if (project === `Cannot find project:${id}`) {
        console.warn(project);
        return;
      }
      this.setState({
        project,
      });
      console.log("Project Attributes", this.state.project);
      this.addNewProject();
    } catch (err) {
      console.log(err);
    }
  }

  async getReasonCodes() {
    try {
      const { data } = await axios.get(global.REACT_APP_API_ENDPOINT + `/reasonCodes/getAllReasonCodes`);
      console.log(data);
      if ( data ) {
        this.setState({
          reasonCodes : data.sort((a, b) => a.reasonCode.localeCompare(b.reasonCode))
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async findProjectPersDetailsByPersId() {
    try {
      let id = this.props.match.params.project_id;
      const { data: pers } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/pers/findProjectPersDetailsByPersId/` +
        id
      );
      this.setState({
        pers,
      });
      console.log("this.state.pers", this.state.pers);
      console.log(this.state.pers.pers1026.phase1026DataList);
      this.addProject();
      await Promise.all([
        this.getProjectDetailsByProjectId(),
        this.getPersDecisionHistory(),
        this.getPersDecisionProfile(),
        this.getPersLetterDetailsHistory(),
        this.listAllDocsByProjectAndPersId(),
        this.getCommentsByProjectAndPers(),
        this.getReasonCodes(),
      ]);
    } catch (err) {
      console.log(err);
    }
  }

  handleChange = (event) => {
    let a = { ...this.state.lender };
    a[event.target.name] = event.target.value;
    this.setState({ lender: a });
  };

  addProject() {
    this.setState({
      lender: this.state.pers.perslen,
      persProjectType: this.state.pers.perslen.persProjectType,
      budget: this.state.pers.persBudget,
      pers1026: this.state.pers.pers1026,
      pers1029: this.state.pers.pers1029,
      pers1051: this.state.pers.pers1051,
      pers1054: this.state.pers.pers1054,
      pers1071: this.state.pers.pers1071,
      pers1081: this.state.pers.pers1081,
      pers1026list: this.state.pers.pers1026.phase1026DataList,
      persId: this.state.pers.perslen.persId,
      projectId: this.state.pers.perslen.projectId,
    });
  }

  addNewProject() {
    this.setState({
      projectName: this.state.project.projectProfile.projectName,
      projectType: this.state.project.projectProfile.newProjectType,
    });
    console.log(
      "this.state.project.projectProfile",
      this.state.project.projectProfile.newProjectType
    );
    console.log("projectType", this.state.projectType);
  }

  collapseAll() {
    if (this.state.collapseKey === "1") {
      this.setState((state) => ({
        collapseKey: "0",
      }));
    } else if (this.state.collapseKey === "0") {
      this.setState((state) => ({
        collapseKey: "1",
      }));
    }
  }

  renderPdf() {
    let list = <h1>Loading...</h1>;
    if (this.state.getPdf) {
      list = <Pdf list={this.state.getPdf} />;
    }
    return list;
  }

  renderDecision() {
    let list = <h1>Loading...</h1>;
    if (this.state.getDecision) {
      list = (
        <Decision list={this.state.getDecision} persId={this.state.persId} reasonCodes={this.state.reasonCodes}/>
      );
    }
    return list;
  }

  renderLetter() {
    let list = <h1>Loading...</h1>;
    if (this.state.getLetter) {
      list = <PersLetter list={this.state.getLetter} />;
    }
    return list;
  }

  renderComments() {
    let list = <h1>Loading...</h1>;
    if (this.state.getComments) {
      list = <Comments list={this.state.getComments} />;
    }
    return list;
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      disablePostButton: true,
    });
    this.setState({
      apiErr: "",
      internalErr: false,
    });

    console.log("comment pass data", this.state);

    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/postComments/`,
        this.state
      )
      .then((response) => {
        console.log(response);
        if (response) {
          if (response.status == 200) {
            this.setState({
              apiErr: "",
              internalErr: false,
            });
            window.location.reload(false);
            // window.location.reload(false);
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              this.setState({
                apiErr: "",
                internalErr: true,
              });
              // window.location.href = "/error-page";
            } else {
              this.setState({
                apiErr: response.data.message,
              });
              // setApiErr(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClickEdit = () => {
    this.setState({
      isEditLender: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditLender: false,
    });
  };

  handleSaveEdit = () => {
    console.log("lender", this.state.lender);
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    return axios
      .post(
        global.REACT_APP_API_ENDPOINT +
        `/pers/updatePersProjectAndForms/lender`,
        this.state.lender
      )
      .then((response) => {
        const data = response.data;
        console.log("api response.. ", data);
        //   dispatch(editPercProjectInfoService(data));
        // return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    // const dispatch = useDispatch();
    // const newData = dispatch(editPersProjectInfo(this.state.lender));

    // this.setState({
    //   isEditLender: false
    // });
  };

  downloadFile = (event) => {
    const a = document.createElement("a");
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    a.style.display = "none";
    a.href =
      global.REACT_APP_API_ENDPOINT +
      `/condoMgr/projectProfile/export/excel/${this.state.projectId}`;
    $(a).attr("download", true);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    const { comments } = this.state;

    // window.onscroll = function() {
    //   scrollFunction();
    // };

    // function scrollFunction() {
    //   if (document.documentElement.scrollTop >= 201) {
    //     document.getElementById("project-profile-top").classList.add("sticky");
    //     document.getElementById("item-a").style.display = "none";
    //     document.getElementById("item-b").style.display = "none";
    //     document.getElementById("item-c").style.display = "none";
    //     document.getElementById("item-d").style.display = "none";
    //     document.getElementById("item-a-sticky").style.display = "block";
    //     document.getElementById("item-b-sticky").style.display = "flex";
    //   } else {
    //     document
    //       .getElementById("project-profile-top")
    //       .classList.remove("sticky");
    //     document.getElementById("item-a").style.display = "block";
    //     document.getElementById("item-b").style.display = "block";
    //     document.getElementById("item-c").style.display = "block";
    //     document.getElementById("item-d").style.display = "block";
    //     document.getElementById("item-a-sticky").style.display = "none";
    //     document.getElementById("item-b-sticky").style.display = "none";
    //   }
    // }

    return (
      <div>
        <div
          className="header-container sticky-top"
          style={{
            marginLeft: "0px",
            paddingLeft: "78px",
            paddingBottom: "25px",
            paddingRight: "66px",
            marginRight: "0px",
          }}
        >
          <div>
            <a className="cookie-dash" href={"/"}>
              <HomeIcon
                style={{
                  fontSize: "1rem",
                  marginRight: "10px",
                  marginBottom: "4px",
                  color: "#0A649D",
                }}
              />
              Home
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            <a
              className="cookie-dash"
              href={`/project/${this.state.lender.projectId}`}
            >
              <img
                alt="Apartment Logo"
                style={{
                  marginRight: "10px",
                  marginBottom: "4px",
                  width: "17px",
                  height: "17px",
                }}
                src={ApartmentBlackIcon}
              />
              {this.state.projectName}
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            <b className="cookie-title">PERS ID: {this.state.lender.persId}</b>
          </div>
          <div className="pers-title-header">
            <p className="project-title">{this.state.projectName}</p>
            <div className="title-header-button-container">
              <PersDropdown
                project_id={this.state.projectId}
                pers_id={this.state.persId}
                pers_status={this.state.lender.persStatus}
                pers_decision={this.state.getHeaderDecision.letterDecisions}
                pers_type={this.state.persProjectType}
              />
              {/* <div className="button-divider" /> */}
              {/* <button
                className="download-record-button"
                onClick={this.downloadFile}
              >
                <GetAppIcon className="get-app-icon" />
                Download Record
              </button> */}
            </div>
          </div>
        </div>
        <div id="project-profile-top" className="project-profile-top">
          {this.state.projectType === "New Condo" ? (
            <div id="item-a" className="item-a">
              <div className="image-pill">
                <div className="image-pill-date">
                  {this.state.lender.projectName}
                </div>
                <div className="image-pill-type">
                  {this.state.projectType} PROJECT
                </div>
              </div>
              <img
                alt="New Condo Image"
                className="center-image"
                src={NewcondoIcon}
              />
            </div>
          ) : (
            <div id="item-a" className="item-a">
              <div className="image-pill-established">
                <div className="image-pill-date">
                  {this.state.lender.projectName}
                </div>
                <div className="image-pill-type">
                  {this.state.projectType} PROJECT
                </div>
              </div>
              <img
                alt="Established Condo Image"
                className="center-image"
                src={EstablishedIcon}
              />
            </div>
          )}
          <div id="item-b" className="item-b">
            <div className="item-b-pers-container">
              <div className="item-b-pers-container-top">
                {/* <img src={Group17Icon} /> */}
                <img
                  alt="Map Icon"
                  src={Group17Icon}
                  height="47px"
                  style={{ height: "47px" }}
                />
              </div>
              <div className="item-b-pers-container-bottom">
                <div className="top">PERS Decision:</div>
                <div className="bottom">
                  {typeof this.state.getHeaderDecision.persDecisionHeader !==
                    "undefined" ? (
                    <>
                      <div>
                        {this.state.getHeaderDecision.persDecisionHeader}
                      </div>
                    </>
                  ) : (
                    <div>Not Available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="item-c" className="item-c">
            <div className="item-c-container">
              <div className="item-c-container-top">
                <img alt="Identification Card Icon" src={Group18Icon} />
              </div>
              <div className="item-c-container-bottom">
                <div className="top">PERS ID Number:</div>
                <div className="bottom">
                  <div>{this.state.persId}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="item-d" className="item-d">
            <div className="item-d-container">
              <div className="item-d-container-top">
                <img alt="Timer Icon" src={Group33Icon} />
                <PersStatus
                  project_id={this.state.projectId}
                  pers_id={this.state.persId}
                  pers_status={this.state.lender.persStatus}
                  pers_type={this.state.projectType}
                />
              </div>
              <div className="item-d-container-bottom">
                <div className="top">PERS Review Status:</div>
                <div className="bottom">
                  Status Changed:
                  <div className="value">
                    {this.state.lender.dtPersStatusChanged === null ? (
                      <>
                        {this.state.lender.dateCreated != null ? (
                          <>{this.state.lender.dateCreated.slice(0, 10)}</>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>{this.state.lender.dtPersStatusChanged}</>
                    )}
                  </div>
                </div>
                <div className="bottom">
                  Status:{" "}
                  <div className="value">{this.state.lender.persStatus}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="item-a-sticky" className="item-a-sticky">
            <div className="pill">
              <div className="date">
                Date Created:{" "}
                {this.state.lender.dateCreated != null ? (
                  <>{this.state.lender.dateCreated.slice(0, 10)}</>
                ) : (
                  ""
                )}
              </div>
              <div className="type">NEW CONDO PROJECT</div>
            </div>
          </div>
          <div id="item-b-sticky" className="item-b-sticky">
            <img alt="Orange Timer Icon" src={Group34Icon} />
            <div className="status">PERS Review Status:</div>
            <div className="prompt">Status Changed:</div>
            <div className="value">
              {this.state.lender.dtPersStatusChanged === null ? (
                <>
                  {this.state.lender.dateCreated != null ? (
                    <>{this.state.lender.dateCreated.slice(0, 10)}</>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>{this.state.lender.dtPersStatusChanged}</>
              )}
            </div>
            <div className="prompt">Status:</div>
            <div className="value">{this.state.lender.persStatus}</div>
            <div className="button-container">
              <PersStatus
                project_id={this.state.projectId}
                pers_id={this.state.persId}
                pers_status={this.state.lender.persStatus}
                pers_type={this.state.projectType}
              />
            </div>
          </div>
        </div>
        <Navbar className="project-nav" sticky="top">
          <Nav className="container-fluid">
            <Nav.Link href="#section0" className="profile-nav-button">
              Lender Questions
            </Nav.Link>
            <Nav.Link href="#section1" className="profile-nav-button">
              PERS Decision
            </Nav.Link>
            <Nav.Link href="#section2" className="profile-nav-button">
              Letter Details
            </Nav.Link>
            <Nav.Link href="#section3" className="profile-nav-button">
              PERS Exhibits
            </Nav.Link>
            <Nav.Link href="#section4" className="profile-nav-button">
              Lender Forms
            </Nav.Link>
            <Nav.Link href="#section5" className="profile-nav-button">
              Comments
            </Nav.Link>
            <div className="ml-auto"></div>
          </Nav>
        </Navbar>
        <button className="collapse-all" onClick={this.collapseAll}>
          <MdFormatLineSpacing className="collapse-all-icon" />
          Collapse All
        </button>

        <LenderAndBuilder
          collapseKey={this.state.collapseKey}
          lender={this.state.lender}
        />
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section1"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">PERS Decisions</p>
              </ContextAwareToggle>
              {!isReadonlyRole() ? (
                <Link
                  to={`/add-new-decision/${this.state.projectId}/${this.state.persId}/${this.state.persProjectType}`}
                >
                  <button className="add-phase-button">Add New Decision</button>
                </Link>
              ) : null}
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderDecision()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section2"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">Letter Details</p>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderLetter()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section3"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">PERS Exhibit</p>
              </ContextAwareToggle>
              <DropzoneModal
                projectvalue={this.state.projectId}
                phasevalue={this.state.phaseId}
                persvalue={this.state.persId}
                projectType={this.state.projectType}
              />
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderPdf()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Pers1026
          projectId={this.state.projectId}
          persId={this.state.persId}
          sectionData={this.state.pers1026}
          collapseKey={this.state.collapseKey}
        />
        <Pers1029
          projectId={this.state.projectId}
          persId={this.state.persId}
          sectionData={this.state.pers1029}
          collapseKey={this.state.collapseKey}
        />
        <Pers1071
          projectId={this.state.projectId}
          persId={this.state.persId}
          sectionData={this.state.pers1071}
          collapseKey={this.state.collapseKey}
        />
        <PersAdditionalInfo
          projectId={this.state.projectId}
          persId={this.state.persId}
          pers1051={this.state.pers1051}
          pers1054={this.state.pers1054}
          pers1081={this.state.pers1081}
          budget={this.state.budget}
          collapseKey={this.state.collapseKey}
        />
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section5"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">Comments</p>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                >
                  {this.state.apiErr ? this.state.apiErr : null}
                  {this.state.internalErr
                    ? "Internal server error while processing request, please contact Fannie Mae technology support"
                    : ""}
                </div>

                {!isReadonlyRole() ? (
                  <form className="comment-form" onSubmit={this.submitHandler}>
                    <div style={{ width: "fit-content" }}>
                      {
                        <textarea
                          className="form-comment-input"
                          required
                          maxLength="5000"
                          placeholder="Type your comment here"
                          type="text"
                          name="comments"
                          value={comments}
                          style={{ width: "88.7%" }}
                          cols={40}
                          rows={10}
                          onChange={(e) => {
                            this.changeHandler(e);
                          }}
                        />
                      }
                      <br />
                      <span
                        style={{
                          display: "inline-block",
                          marginRight: "5px",
                          float: "right",
                          fontSize: "14px",
                          color: "#787979",
                        }}
                      >{`${this.state.commentCharCount}/5000`}</span>
                    </div>{" "}
                    <br />
                    <button
                      className="form-submit-icon"
                      type="submit"
                      disabled={this.state.disablePostButton}
                    >
                      Post
                      <FaPaperPlane className="" />
                    </button>
                  </form>
                ) : null}
                {this.renderComments()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}
export default Pers;
