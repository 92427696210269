const AuditTrailActions = [
  'Update Phase',
  'Update Project',
  'Delete Project',
  'Delete Phase',
  'cg_condo_project',
  'cg_condo_phase',
  'addBanner',
  'editBanner',
  'saveProjectProfile',
  'saveManuallyOverriddenFMReviewStatusForProject',
  'addNewPhase',
  'saveNewPhase',
  'addNewChildPhase',
  'editChildPhase',
  'saveManuallyOverriddenFMReviewStatusForPhase',
  'savePersProject',
  'savePersProjectAndForms',
  'saveEstablishedPersProjectAndForms',
  'generateConditionalAvailableLetterPdf',
  'generateConditionalUnavailableLetterPdf',
  'generateFinalLetterPdf',
  'generateIneligibleLetterPdf',
  'generateSuspensionLetterPdf',
  'updateEstablishedPersProjectAndForms',
  'updatePersProjectAndForms',
  'saveNewPersDecision',
  'saveProjectManualDeliveryRestrictions',
  'savePhaseManualDeliveryRestrictions',
  'serveImage',
  'serveFile',
  'deleteFile',
  'editNotesForAttachmentsByDocMapId',
  'uploadFileByProject',
  'uploadFileByProjectAndPers',
  'uploadFileByProjectAndPhase',
  'uploadImage',
  'postFileuploadContext',
  'downloadFile',
  'getLoanDeliveryDetailsByProjectId',
  'postComments',
  'runLenderCertificationRules',
  'saveLenderCertificationFormsPhase',
  'cancelCertification',
  'getLatestIncompleteLenderCertificationForPhase',
  'exportAllCertificationActivity',
  'exportAllCertificationActivityForLender',
  'exportAllCertificationDetails',
  'exportAllProjectAndPhaseDataToExcel',
  'exportApprovedProjectsAndPhases',
  'exportUnavailableProjectsAndPhases',
  'Update Project Cert',
  'Update Phase Cert',
  'saveActivityFeedReasonCodes',
  'Insert Reason Code',
  'Update Reason Code'
];

export {AuditTrailActions};