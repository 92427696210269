import React, { Component } from "react";
import axios from "axios";
import { Nav, Navbar, Accordion, Card } from "react-bootstrap";
import { FaPen, FaSave } from "react-icons/fa";
import { MdFormatLineSpacing } from "react-icons/md";
import ContextAwareToggle from "../../Profile/ContextAwareToggle";
import ErrorPage from "../../../error-page";
import { UpdatedField as UpdatedProjectField } from "../../Pers-atoms";
import "./childphasepage.css";
import HomeIcon from "@material-ui/icons/Home";
import RemoveIcon from "@material-ui/icons/Remove";
import ContainerCModal from "../../Project/ContainerCModal";
import FannieMaeIcon from "../../../images/icons/Shape@1x.svg";
import FeedbackRemoveIcon from "../../../images/icons/feedback-remove@1x.svg";
import SuccessIcon from "../../../images/icons/Success@1x.svg";
import TimerIcon from "../../../images/icons/timer@1x.svg";
import WarningIcon from "../../../images/icons/Warning@1x.svg";
import EditIcon from "../../../images/icons/edit@1x.svg";
import Success1Icon from "../../../images/icons/Success-1@1x.svg";
import Warning1Icon from "../../../images/icons/Warning-1@1x.svg";
import DeleteModal from "../../Project/delete-modal";
import {
  isProjectStandardRole,
  isLenderRole,
  isTPORole,
  isReadonlyRole,
  isInternal,
  isAdminRole,
} from "../../../redux/actions/userAction";
import Restrictions from "../../Restrictions/Restrictions";
import ProjectRestrictions from "../../Restrictions/Project/RestrictionsProject";
import ChildPhaseRestrictions from "../../Restrictions/ChildPhase/ChildPhaseRestrictions";
import { Grid, Button, Tooltip } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import ProjectPreRestrictions from "../../Project/ProjectPreRestrictions";
import ProjectPreRestrictionsModal from "../../Project/ProjectPreRestrictionsModal";
import InfoIcon from "@material-ui/icons/Info";
import { withStyles } from "@material-ui/core/styles";
import ApprovalConditions from "../../ApprovalConditions/ApprovalConditions";
import FmActivityFeed from "../../FmActivityFeed/FmActivityFeed";
import ApartmentIcon from "../../../images/apartment_black_24dp-1@2x.svg";
import LayersBlackIcon from "../../../images/layers_black_24dp-1@2x.svg";
import ConstructionBlackIcon from "../../../images/construction_black_24dp@1x.svg";
import CampaignBlackIcon from "../../../images/campaign_black_24dp@1x.svg";
import NewCondoIcon from "../../../images/newcondo-image.png";
import Group17Icon from "../../../images/Group 17.svg";
import CriticalIcon from "../../../images/Critical@2x.svg";
import Group34Icon from "../../../images/Group-34.svg";
import AddCircleIcon from "../../../images/add_circle@1x.svg";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getDays } from "../../shared/services/SharedService";

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
})(Tooltip);

class ChildPhasePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: [],
      collapseKey: "1",
      containsPers: false,
      showContainerCModal: false,
      phaseRestrictions: [],
      showHideRestrictions: false,
      activeTab: "pre",
      formData: [],
      types: [
        { key: "Construction", value: "Construction" },
        { key: "Marketing", value: "Marketing" },
      ],
      approvalConditions: [],
      fmActivity: [],
      projectError: "",
    };
  }

  async componentDidMount() {
    let id = this.props.match.params.phase_id;
    let role = sessionStorage.getItem("role");
    if (role) {
      this.init(id);
    } else {
      this.getUserDetails(id);
    }
  }

  async init(id) {
    this.viewChildPhase(id);
  }

  async viewChildPhase(id) {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    let project;
    let projectId;
    let parentPhaseId;
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/phase/viewChildPhase/` + id)
      .then(async (response) => {
        if (response.status == 200) {
          project = response.data;
          this.setState({
            project,
          });
          projectId = project.projectId;
          parentPhaseId = project.parentPhaseId;
          await Promise.all([
            this.getChildPhaseDeliveryRestrictions(projectId, parentPhaseId, id),
            this.lenderViewPersDecisionConditionsForChildPhase(projectId, id),
            this.getFnmaReviewStatusActivityFeedForPhase(projectId, id),
            this.editPhaseManualDeliveryRestrictions(projectId, id),
            this.lenderViewChildPhase(passData),
            this.getReasonCodes(),
            this.checkPersExistForPhase(),
          ]);
        }
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  async getFnmaReviewStatusActivityFeedForPhase(projectId, id) {
    try {
      const { data } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/activityFeed/getFnmaReviewStatusActivityFeedForPhase/${projectId}/${id}`
      );
      this.setState({ fmActivity: data.activityFeedFnmaStatusDTO || [] });
      console.log("********************", data);
    } catch (error) {
      throw Error(error);
    }
  }

  async lenderViewChildPhase(passData) {
    if (isLenderRole() || isTPORole()) {
      try {
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const id = this.props.match.params.phase_id;
        const { data: project } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/lender/lenderViewChildPhase/${id}/${projectId}/${lenderId}`
        );
        this.setState({
          project,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getReasonCodes() {
    try {
      const { data } = await axios.get(global.REACT_APP_API_ENDPOINT + `/reasonCodes/getAllReasonCodes`);
      console.log(data);
      if (data) {
        this.setState({
          reasonCodes: data.sort((a, b) => a.reasonCode.localeCompare(b.reasonCode))
        });
      }
    } catch (err) {
      console.log(err);
    }
  };


  async checkPersExistForPhase() {
    try {
      let id = this.props.match.params.phase_id;
      const { data: containsPers } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/phase/checkPersExistForPhase/` + id
      );
      this.setState({
        containsPers,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async editPhaseManualDeliveryRestrictions(projectId, id) {
    if (isProjectStandardRole()) {
      try {
        const { data: formData } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/delvyRestrictions/editPhaseManualDeliveryRestrictions/` +
          projectId +
          `/` +
          id
        );
        this.setState({
          formData,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async lenderViewPersDecisionConditionsForChildPhase(projectId, id) {
    try {
      if (isLenderRole() || isTPORole()) {
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const { data: lenderApprovalConditions } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/lender/lenderViewPersDecisionConditionsForChildPhase/` +
          id +
          `/` +
          +projectId +
          `/` +
          lenderId
        );
        this.setState({
          approvalConditions: lenderApprovalConditions,
        });
      } else {
        const { data: approvalConditions } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
          `/phase/getPersDecisionConditionsForChildPhase/` +
          projectId +
          `/` +
          id
        );
        this.setState({
          approvalConditions: approvalConditions,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getChildPhaseDeliveryRestrictions(projectId, parentPhaseId, id) {
    try {
      const { data: phaseRestrictions } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
        `/delvyRestrictions/getChildPhaseDeliveryRestrictions/` +
        projectId +
        `/` +
        parentPhaseId +
        `/` +
        id
      );
      this.setState({
        phaseRestrictions,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async getUserDetails(id) {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/translatedRoleMap/`)
      .then((response) => {
        if (response.status == 200) {
          sessionStorage.setItem("roleData", JSON.stringify(response.data));
          if (!sessionStorage.getItem("role")) {
            sessionStorage.setItem("role", response.data.selectedRole);
          }
          const passData = JSON.parse(sessionStorage.getItem("passData"));
          this.init(id);
        }
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  collapseAll() {
    if (this.state.collapseKey === "1") {
      this.setState((state) => ({
        collapseKey: "0",
      }));
    } else if (this.state.collapseKey === "0") {
      this.setState((state) => ({
        collapseKey: "1",
      }));
    }
  }

  toggleEdit = (flag) => {
    this.setState({
      isEdit: flag,
    });
    if (!flag) {
      this.setState({
        projectError: "",
      });
      this.getData();
    }
  };

  handleChange = (event) => {
    this.state.project[event.target.name] = event.target.value;
    this.setState({
      project: this.state.project,
    });
  };

  handleSaveEdit = async () => {
    if (this.state.project.phaseName == "") {
      this.setState({
        legalNameError: "This field is required",
      });
    } else {
      this.setState({
        legalNameError: "",
      });
    }

    const regexUnit = new RegExp(/^[0-9]*$/);
    if (this.state.project.unitCount.length == 0) {
      this.setState({
        totalUnitError: "This field is required",
      });
    } else if (this.state.project.unitCount <= 0) {
      this.setState({
        totalUnitError: "Number of units cannot be 0 or negative",
      });
    } else if (regexUnit.test(this.state.project.unitCount) === false) {
      this.setState({
        totalUnitError: "Invalid Unit Number",
      });
    } else {
      this.setState({
        totalUnitError: "",
      });
    }

    if (this.state.project.street == "") {
      this.setState({
        addressError: "This field is required",
      });
    } else {
      this.setState({
        addressError: "",
      });
    }
    if (
      this.state.project.projectName !== "" &&
      parseInt(this.state.project.unitCount) > 0 &&
      this.state.projectError == "" &&
      this.state.project.street !== ""
    ) {
      this.saveDataAPI(this.state.project);
    }
  };

  getData() {
    // try {
    let id = this.props.match.params.phase_id;
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/phase/viewChildPhase/` + id)
      .then((response) => {
        console.log("success update", response);

        this.setState({
          project: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  saveDataAPI(project) {
    console.log("save API call");
    this.setState({
      apiErrProject: "",
      internalErrProject: false,
    });
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    console.log("project data", project);
    const phaseData = {
      buildingNumber: project.buildingNumber,
      city: project.city,
      comments: project.comments,
      parentPhaseId: project.parentPhaseId,
      phaseId: project.phaseId,
      phaseName: project.phaseName,
      phaseType: project.phaseType,
      projectId: project.projectId,
      projectName: project.projectName,
      state: project.state,
      street: project.street,
      unitCount: project.unitCount,
      unitNumber: project.unitNumber,
      userName: passData.user,
      zip5: project.zip5,
    };

    axios
      .post(global.REACT_APP_API_ENDPOINT + `/phase/editChildPhase`, phaseData)
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            isEdit: false,
            apiErrProject: "",
            internalErrProject: false,
          });
          this.getData();
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            this.setState({
              internalErrProject: true,
              apiErrProject: "",
              isEdit: false,
            });
            // window.location.href = "/error-page";
          } else {
            this.setState({
              apiErrProject: response.data.message,
            });
          }
        }
        // window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProjectDetailStatus = (projectData) => {
    if (projectData) {
      // projectData.projectProfile.fnmaReviewStatus
      switch (projectData.fnmaReviewStatus) {
        case "Approved By Fannie Mae":
          return {
            icon: <img src={SuccessIcon} alt="Success Icon" />,
            name: "Approved By Fannie Mae",
          };
        case "Pending PERS Review":
          return {
            icon: <img src={TimerIcon} alt="Timer Icon" />,
            name: "Pending PERS Review",
          };
        case "Conditional Approval":
          return {
            icon: <img src={WarningIcon} alt="Warning Icon" />,
            name: "Conditional Approval",
          };
        case "Unavailable":
          return {
            icon: <img src={FeedbackRemoveIcon} alt="Removal Icon" />,
            name: "Unavailable",
          };
        case "Fannie Mae Approval Expired":
          return {
            icon: <img alt="Alternate Success Icon" src={Success1Icon} />,
            name: "Fannie Mae Approval Expired",
          };
        case "Fannie Mae Conditional Approval Expired":
          return {
            icon: <img alt="Alternate Warning Icon" src={Warning1Icon} />,
            name: "Conditional Approval Expired",
          };
        default:
          return { icon: null, name: "No Fannie Mae Review" };
      }
    }
  };

  showMannualOverrideButton = (projectData) => {
    if (projectData) {
      if (projectData.showManualOverrideButton
        && isAdminRole() && this.state.containsPers) {
        return false;
      }
      return projectData.showManualOverrideButton;
    }
  };

  getDetails = (data) => {
    return {
      type: "childPhase",
      projectId: data.projectId,
      phaseId: data.phaseId,
      fnmaReviewStatus: data.fnmaReviewStatus,
    };
  };

  getSavedChildPhaseResponse = (response) => {
    console.log("savedres", response);
    this.setState({
      project: {
        ...this.state.project,
        showManualOverrideButton: response.showManualOverrideButton,
        fnmaReviewStatus: response.fnmaReviewStatus,
        fnmaReviewStatusDtExpiry: response.approvalExpiryDate
      },
      showContainerCModal: false,
    });
  };

  getExpiry = (projectData) => {
    if (projectData) {
      return projectData.fnmaReviewStatusDtExpiry;
    }
  };

  getDropDownStatusVal = (projectData) => {
    if (projectData) {
      console.log("inside getdropdownval $$$$   ", projectData);
      return projectData.manualOverrideCanChangeTo;
    }
  };

  renderDecision() {
    let list = <p>Loading...</p>;
    if (this.state.phaseRestrictions.phaseDR) {
      list = <Restrictions list={this.state.phaseRestrictions.phaseDR} />;
    }
    return list;
  }

  renderDecision2() {
    let list = <p>Loading...</p>;
    if (this.state.phaseRestrictions.projectDR) {
      list = (
        <ProjectRestrictions list={this.state.phaseRestrictions.projectDR} />
      );
    }
    return list;
  }

  renderChildPhaseDecision() {
    let list = <p>Loading...</p>;
    if (this.state.phaseRestrictions.childPhaseDR) {
      list = (
        <ChildPhaseRestrictions
          list={this.state.phaseRestrictions.childPhaseDR}
          type={this.state.project.phaseType}
        />
      );
    }
    return list;
  }

  renderFmActivity() {
    const { fmActivity } = this.state;
    let list = <h1>Loading...</h1>;
    if (fmActivity) {
      //consider moving this to a component when doing the fm activity feed for phase and childphase
      list = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          {fmActivity.map((ele, index) => (
            <FmActivityFeed ele={ele} index={index} reasonCodes={this.state.reasonCodes} />
          ))}
        </div>
      );
    }
    return list;
  }

  showHideRestrictionsComponent() {
    this.setState({
      showHideRestrictions: !this.state.showHideRestrictions,
    });
  }

  handleSaveRestrictions = async () => {
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    let userId = passData.user;

    let preConSelect = [];
    if (this.state.formData.preCannedDR) {
      preConSelect = this.state.formData.preCannedDR.filter(
        (c) => c.isExistingRestrictions
      );
    }

    let customConSelect = [];
    if (this.state.formData.customDR) {
      customConSelect = this.state.formData.customDR.filter(
        (c) => c.isExistingRestrictions
      );
    }

    let addedConSelect = [];
    if (this.state.formData.addedRestrictions) {
      addedConSelect = this.state.formData.addedRestrictions.map(
        ({ restrictionsName, restrictionsDescription }) => ({
          restrictionsName,
          restrictionsDescription,
          isAddedRestrictions: true,
        })
      );
    }

    this.setState({
      apiErrRestrictions: "",
      internalErrRestrictions: false,
    });

    this.setState({ restrictionErr: "" });
    const comboSelect = preConSelect.concat(addedConSelect, customConSelect);
    const entirePhaseData = {
      projectId: this.state.project.projectId,
      phaseId: this.props.match.params.phase_id,
      createdById: userId,
      manRestrictions: comboSelect,
    };
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
        `/delvyRestrictions/savePhaseManualDeliveryRestrictions/`,
        entirePhaseData
      )
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            isEdit: false,
            apiErrRestrictions: "",
            internalErrRestrictions: false,
          });
          this.getData();
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            this.setState({
              internalErrRestrictions: true,
              apiErrRestrictions: "",
              isEdit: false,
            });
            // window.location.href = "/error-page";
          } else {
            this.setState({
              apiErrRestrictions: response.data.message,
            });
          }
        }
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renderTooltipRest() {
    return (
      <div style={{ lineHeight: 1.5, fontSize: 14 }}>
        <span style={{ color: "#1c6fa3", width: 16, height: 16 }}>
          {" "}
          <InfoIcon />
        </span>
        <br />
        <div
          style={{
            color: "rgba(0,0,0,0.82)",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            letterSpacing: "0",
            lineHeight: "24px",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          There is a PERS associated to this Phase. Please use the PERS decision
          workflow to edit Delivery Restrictions.
        </div>
      </div>
    );
  }

  render() {
    const { showHideRestrictions } = this.state;
    const setRestrictionsData = (_restrictions, name) => {
      const _formData = { ...this.state.formData, [name]: [..._restrictions] };
      this.setState({ formData: _formData });
    };
    const handleAddClose = () => this.setState({ showAdd: false });
    const handleAddClick = (newRestriction) => {
      const _formData = {
        ...this.state.formData,
        addedRestrictions: [
          ...(this.state.formData.addedRestrictions || []),
          { ...newRestriction },
        ],
      };
      this.setState({ formData: _formData });
      this.setState({ showAdd: false });
    };
    const handleAddShow = () => this.setState({ showAdd: true });
    const changeTab = (params) => {
      this.setState({ activeTab: params });
    };
    const { addedRestrictions = [], restrictions = [] } = this.state.formData;

    return (
      <div>
        <div
          className="title-container sticky-top"
          style={{ marginLeft: "0px", paddingLeft: "78px" }}
        >
          <div>
            <a className="update-cookie-dash" href={"/"}>
              <HomeIcon
                style={{
                  fontSize: "1rem",
                  marginRight: "10px",
                  marginBottom: "4px",
                  color: "#0A649D",
                }}
              />
              Home
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            <a
              className="update-cookie-dash"
              href={`/project/${this.state.project.projectId}`}
            >
              <img
                alt="Apartment Logo"
                style={{
                  marginRight: "10px",
                  marginBottom: "4px",
                  width: "17px",
                  height: "17px",
                }}
                src={ApartmentIcon}
              />
              {this.state.project.projectName}
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            <a
              className="update-cookie-dash"
              href={`/project/phase/${this.state.project.parentPhaseId}`}
            >
              <img
                alt="Phase Logo"
                style={{
                  marginRight: "10px",
                  marginBottom: "4px",
                  width: "17px",
                  height: "17px",
                }}
                src={LayersBlackIcon}
              />
              {this.state.project.parentPhaseName}
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            {this.state.project.phaseType === "Construction" ? (
              <a className="update-cookie-dash">
                <img
                  alt="Hammer and Wrench Logo"
                  style={{
                    marginRight: "10px",
                    marginBottom: "4px",
                    width: "17px",
                    height: "17px",
                  }}
                  src={ConstructionBlackIcon}
                />
                {this.state.project.phaseName}
              </a>
            ) : this.state.project.phaseType === "Marketing" ? (
              <a className="update-cookie-dash">
                <img
                  alt="Blow Horn Black Logo"
                  style={{
                    marginRight: "10px",
                    marginBottom: "4px",
                    width: "17px",
                    height: "17px",
                  }}
                  src={CampaignBlackIcon}
                />
                {this.state.project.phaseName}
              </a>
            ) : (
              <></>
            )}
          </div>
          <p className="update-project-name">{this.state.project.phaseName}</p>
          <p className="project-id-container">
            {this.state.project.phaseType === "Construction" ? (
              <img
                alt="Hammer and Wrench Logo"
                style={{ marginRight: "10px", marginBottom: "4px" }}
                src={ConstructionBlackIcon}
              />
            ) : this.state.project.phaseType === "Marketing" ? (
              <img
                alt="Blow Horn Black Logo"
                style={{ marginRight: "10px", marginBottom: "4px" }}
                src={CampaignBlackIcon}
              />
            ) : (
              <></>
            )}
            Child Phase ID: {this.state.project.phaseId}
          </p>
        </div>

        <div id="project-profile-top" className="project-profile-top">
          <div id="item-a" className="item-a">
            <div className="child-image-pill">
              <div className="image-pill-date">
                {this.state.project.projectName}
              </div>
              <div className="image-pill-type">
                {this.state.project.phaseType} PHASE
              </div>
            </div>
            <img
              alt="New Condo Image"
              className="center-image"
              src={NewCondoIcon}
            />
          </div>
          <div id="item-b" className="item-b">
            <div className="item-b-container">
              <div className="item-b-container-top">
                <img
                  alt="Map Icon"
                  src={Group17Icon}
                  height="47px"
                  style={{ height: "47px" }}
                />
              </div>
              <div className="item-b-container-bottom">
                <div className="top">
                  {this.state.project.phaseType} Phase Address:
                </div>
                <div className="bottom">
                  {this.state.project.street != "" ? (
                    <>
                      <div>{this.state.project.street}</div>
                      <div>
                        {this.state.project.city}, {this.state.project.state}{" "}
                        {this.state.project.zip5}
                      </div>
                    </>
                  ) : (
                    <div>Not Available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="item-c" className="item-c">
            <div className="item-c-container">
              <div className="item-c-container-top">
                {isInternal() ? (
                  <>
                    {this.showMannualOverrideButton(this.state.project) && (
                      <img
                        alt="Pencil Icon"
                        src={EditIcon}
                        className="item-c-container-top-edit"
                        onClick={() => {
                          this.setState({
                            showContainerCModal: true,
                          });
                        }}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
                <img
                  src={FannieMaeIcon}
                  alt="Fannie Mae Icon"
                  style={{ marginRight: "13px" }}
                />
                {this.getProjectDetailStatus(this.state.project) &&
                  this.getProjectDetailStatus(this.state.project).icon}
              </div>
              <div className="item-c-container-bottom">
                <div className="top">Fannie Mae Review Status </div>
                <div className="bottom">
                  <div>
                    {this.getProjectDetailStatus(this.state.project) &&
                      this.getProjectDetailStatus(this.state.project).name}
                  </div>
                </div>
                {this.getExpiry(this.state.project) ? (
                  <div style={{ display: "flex" }}>
                    <div>Expires: </div>
                    <div>
                      <span style={{ fontWeight: "bold", paddingLeft: "5px" }}>
                        {
                          this.state.project.fnmaReviewStatus === 'Approved By Fannie Mae' && getDays(this.getExpiry(this.state.project)) <= 120 &&
                          <CustomTooltip title={"Expires in " + getDays(this.getExpiry(this.state.project)) + " days"} placement="bottom"><AccessTimeIcon className="clock" /></CustomTooltip>
                        }
                        {this.getExpiry(this.state.project)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div id="item-a-sticky" className="item-a-sticky"></div>
          <div id="item-b-sticky" className="item-b-sticky">
            <img alt="Timer Icon" src={Group34Icon} />
            <div className="status"></div>
            <div className="prompt"></div>
            <div className="value"></div>
            <div className="prompt">Phase ID:</div>
            <div className="value">{this.state.project.phaseId}</div>
            <div className="button-container"></div>
          </div>
        </div>
        <Navbar className="project-nav" sticky="top">
          <Nav className="container-fluid">
            <Nav.Link href="#section0" className="profile-nav-button">
              {this.state.project.phaseType} Phase Information
            </Nav.Link>
            <Nav.Link href="#section1" className="profile-nav-button">
              Fannie Mae Activity Feed
            </Nav.Link>
            <div className="ml-auto"></div>
          </Nav>
        </Navbar>
        <button className="collapse-all" onClick={this.collapseAll}>
          <MdFormatLineSpacing className="collapse-all-icon" />
          Collapse All
        </button>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section0"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">
                  {this.state.project.phaseType} Phase Information
                </p>
              </ContextAwareToggle>
              {isProjectStandardRole() ? (
                <>
                  {this.state.isEdit ? (
                    <>
                      {this.state.containsPers === false ? (
                        <DeleteModal
                          type="child-phase"
                          projectvalue={this.state.project.projectId}
                          phasevalue={this.state.project.parentPhaseId}
                          childphasevalue={this.state.project.phaseId}
                        />
                      ) : (
                        <></>
                      )}
                      <div>
                        <button
                          className="cancel-button"
                          onClick={() => {
                            this.toggleEdit(false);
                            this.setState({
                              apiErrProject: false,
                            });
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          //   disabled
                          className="add-phase-button"
                          onClick={this.handleSaveEdit}
                        >
                          <FaSave className="edit-profile-icon" /> Save Edits
                        </button>
                      </div>
                    </>
                  ) : (
                    <button
                      //   disabled
                      className="add-phase-button"
                      onClick={() => {
                        this.toggleEdit(true);
                        this.setState({
                          internalErrProject: false,
                          apiErrProject: "",
                        });
                      }}
                    >
                      <FaPen className="edit-profile-icon" /> Edit Section
                    </button>
                  )}
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-grid-body-card">
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                >
                  {this.state.apiErrProject ? this.state.apiErrProject : null}
                </div>
                {this.state.internalErrProject ? (
                  <ErrorPage></ErrorPage>
                ) : (
                  <div className="accordion-grid-parent">
                    {this.state.phaseRestrictions.showAddManualRestrictions ===
                      true ? (
                      <>
                        {this.state.phaseRestrictions
                          .showAddManualRestrictions === true ? (
                          <>
                            {isProjectStandardRole() && (
                              <div className="bigbutton">
                                <div
                                  onClick={() =>
                                    this.showHideRestrictionsComponent()
                                  }
                                  className="add-restrictions-button"
                                >
                                  <img
                                    alt="Blue Add Icon"
                                    className="button-image"
                                    src={AddCircleIcon}
                                  />
                                  Add Delivery Restrictions
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {showHideRestrictions ? (
                      <>
                        {this.state.apiErrRestrictions
                          ? this.state.apiErrRestrictions
                          : null}

                        <div className="bigbutton">
                          <div className="add-restrictions-button">
                            <img
                              alt="Critical Warning Icon"
                              className="button-image"
                              src={CriticalIcon}
                            />
                            Add Restrictions{" "}
                            <div style={{ float: "right" }}>
                              <Button
                                style={{ textTransform: "none" }}
                                onClick={() =>
                                  this.showHideRestrictionsComponent()
                                }
                              >
                                Cancel
                              </Button>
                              <Button
                                style={{
                                  color: "#FFFFFF",
                                  backgroundColor: "#1176B5",
                                  textTransform: "none",
                                }}
                                onClick={this.handleSaveRestrictions}
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                        <Grid
                          item
                          xl
                          style={{
                            gridArea: "attributes3",
                            margin: "0px 40px",
                          }}
                        >
                          <div
                            className="btn-tab-container"
                            style={{ borderTop: 0, marginTop: 0 }}
                          >
                            <Grid
                              item
                              xl
                              className="condition-container"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div>
                                <Button
                                  onClick={handleAddShow}
                                  className="button"
                                >
                                  <Add
                                    style={{
                                      color: "#0F5AF0",
                                    }}
                                  />{" "}
                                  Create custom restriction
                                </Button>
                              </div>
                            </Grid>
                            <div className="tab-view-container">
                              <Button
                                className={
                                  this.state.activeTab == "pre"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() => changeTab("pre")}
                              >
                                Pre-canned (
                                {typeof this.state.formData.preCannedDR !==
                                  "undefined"
                                  ? this.state.formData.preCannedDR.length
                                  : ""}
                                )
                              </Button>
                              <Button
                                className={
                                  this.state.activeTab == "add"
                                    ? "tab-title active-tab"
                                    : "tab-title"
                                }
                                onClick={() => changeTab("add")}
                              >
                                Custom (
                                {typeof this.state.formData.customDR !==
                                  "undefined"
                                  ? this.state.formData.customDR.length +
                                  addedRestrictions.length
                                  : addedRestrictions.length}
                                )
                              </Button>
                            </div>
                          </div>
                          <div style={{ height: "200px", overflowY: "auto" }}>
                            {this.state.activeTab == "pre" ? (
                              <ProjectPreRestrictions
                                formData={this.state.formData}
                                name="preCannedDR"
                                setRestrictionsData={setRestrictionsData}
                                isPre
                              />
                            ) : (
                              <>
                                <ProjectPreRestrictions
                                  formData={this.state.formData}
                                  name="customDR"
                                  setRestrictionsData={setRestrictionsData}
                                  isPre
                                />
                                <ProjectPreRestrictions
                                  formData={this.state.formData}
                                  name="addedRestrictions"
                                  setRestrictionsData={setRestrictionsData}
                                />
                              </>
                            )}
                          </div>
                          <div className="mendatory-sign">
                            {this.state.restrictionErr}
                          </div>

                          <ProjectPreRestrictionsModal
                            showAdd={this.state.showAdd}
                            handleAddClose={handleAddClose}
                            handleAddClick={handleAddClick}
                            formData={this.state.formData}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <div className="header">
                          <div className="title">Overview</div>
                        </div>
                        <div className="attributes">
                          <div className="attri-container">
                            <div className="attri-title">
                              Phase Name{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Phase Name"
                                  type="text"
                                  className="form-control"
                                  name="phaseName"
                                  value={this.state.project.phaseName}
                                  maxLength="300"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        projectError: "This field is required",
                                      });
                                    } else {
                                      this.setState({
                                        projectError: "",
                                      });
                                    }
                                  }}
                                />

                                <div style={{ color: "#ff0000" }}>
                                  {this.state.projectError}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.project.phaseName}{" "}
                              </div>
                            )}
                          </div>

                          <UpdatedProjectField
                            label="Phase Type"
                            name="phaseType"
                            formData={this.state.project}
                            handleChange={this.handleChange}
                            isEdit={this.state.isEdit}
                            dropdown={true}
                            dropdownValues={this.state.types}
                          />

                          <div className="attri-container">
                            <div className="attri-title">
                              Number of Units in Phase{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Number of Units in Phase"
                                  type="text"
                                  className="form-control"
                                  name="unitCount"
                                  min="0"
                                  maxLength="64"
                                  value={this.state.project.unitCount}
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    const regex = new RegExp(/^[0-9]*$/);
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        totalUnitError:
                                          "This field is required",
                                      });
                                    } else if (event.target.value <= 0) {
                                      this.setState({
                                        totalUnitError:
                                          "Number of units cannot be 0 or negative",
                                      });
                                    } else if (
                                      regex.test(event.target.value) === false
                                    ) {
                                      this.setState({
                                        totalUnitError: "Invalid Unit Number",
                                      });
                                    } else {
                                      this.setState({
                                        totalUnitError: "",
                                      });
                                    }
                                  }}
                                />
                                <div style={{ color: "#ff0000" }}>
                                  {this.state.totalUnitError
                                    ? this.state.totalUnitError
                                    : null}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.project.unitCount}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">Building Number</div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  type="text"
                                  aria-label="Building Number"
                                  className="form-control"
                                  name="buildingNumber"
                                  value={this.state.project.buildingNumber}
                                  maxLength="100"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.project.buildingNumber}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">Unit Number</div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  type="text"
                                  aria-label="Unit Number"
                                  className="form-control"
                                  name="unitNumber"
                                  value={this.state.project.unitNumber}
                                  maxLength="100"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.project.unitNumber}{" "}
                              </div>
                            )}
                          </div>
                          <div className="attri-container">
                            <div className="attri-title">
                              Phase Address{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            {this.state.isEdit ? (
                              <div className="attri-value">
                                <input
                                  required
                                  aria-label="Phase Address"
                                  type="text"
                                  className="form-control"
                                  name="street"
                                  value={this.state.project.street}
                                  maxLength="256"
                                  onChange={(event) => {
                                    this.handleChange(event);
                                    if (event.target.value.length == 0) {
                                      this.setState({
                                        addressError: "This field is required",
                                      });
                                    } else {
                                      this.setState({
                                        addressError: "",
                                      });
                                    }
                                  }}
                                />

                                <div style={{ color: "#ff0000" }}>
                                  {this.state.addressError}
                                </div>
                              </div>
                            ) : (
                              <div className="attri-value">
                                {this.state.project.street}{" "}
                              </div>
                            )}
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              City <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            <div className="attri-value">
                              {this.state.project.city}{" "}
                            </div>
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              State <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            <div className="attri-value">
                              {this.state.project.state}{" "}
                            </div>
                          </div>

                          <div className="attri-container">
                            <div className="attri-title">
                              Zip Code{" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                            </div>
                            <div className="attri-value">
                              {this.state.project.zip5}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="restrictions">
                          {typeof this.state.phaseRestrictions.phaseDR !==
                            "undefined" ? (
                            <>
                              {this.state.phaseRestrictions.phaseDR.length ==
                                0 &&
                                this.state.phaseRestrictions.projectDR.length ==
                                0 &&
                                this.state.phaseRestrictions.childPhaseDR
                                  .length == 0 ? (
                                <div></div>
                              ) : (
                                <div className="dlvy-restrictions">
                                  <div className="restrictions-red-parent">
                                    <div className="restrictions-title">
                                      <img
                                        alt="Critical Warning Icon"
                                        className="restrictions-image"
                                        src={CriticalIcon}
                                      />
                                      Delivery Restrictions{" "}
                                      {isProjectStandardRole() && this.state.phaseRestrictions
                                        .showAddManualRestrictions === true ? (
                                        <div
                                          style={{
                                            float: "right",
                                            cursor: "pointer",
                                            paddingRight: "11.In5px",
                                          }}
                                          onClick={() =>
                                            this.showHideRestrictionsComponent()
                                          }
                                        >
                                          <FaPen
                                            style={{ color: "#1176b5" }}
                                            className="edit-profile-icon"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            float: "right",
                                            cursor: "pointer",
                                            paddingRight: "11.5px",
                                          }}
                                        >
                                          <CustomTooltip
                                            style={{
                                              backgroundColor: "white",
                                            }}
                                            title={this.renderTooltipRest()}
                                            placement="left"
                                            arrow
                                          >
                                            <span
                                              id="request-phase"
                                              style={{
                                                cursor: "pointer",
                                                color: "#1c6fa3",
                                                fontSize: 14,
                                              }}
                                            >
                                              <InfoIcon
                                                style={{
                                                  width: 20,
                                                  height: 18,
                                                  marginBottom: "3px",
                                                }}
                                              />
                                            </span>
                                          </CustomTooltip>
                                        </div>
                                      )}
                                    </div>
                                    <div className="project-phase-restrictions">
                                      {this.renderDecision2()}
                                    </div>
                                    <div className="project-phase-restrictions">
                                      {this.renderDecision()}
                                    </div>
                                    <div className="project-phase-restrictions">
                                      {this.renderChildPhaseDecision()}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {!isTPORole() && typeof this.state.approvalConditions &&
                            this.state.approvalConditions.childPhaseCondnList ? (
                            <>
                              {this.state.approvalConditions
                                .childPhaseCondnList &&
                                this.state.approvalConditions.childPhaseCondnList
                                  .length == 0 ? (
                                <div></div>
                              ) : (
                                <ApprovalConditions
                                  projectlist={[]}
                                  phaseList={[]}
                                  childPhaseCondnList={
                                    this.state.approvalConditions
                                      .childPhaseCondnList
                                  }
                                  type={this.state.project.phaseType}
                                ></ApprovalConditions>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}{" "}
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section1"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">
                  {" "}
                  Fannie Mae Activity Feed
                </p>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderFmActivity()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <ContainerCModal
          showContainerCModal={this.state.showContainerCModal}
          projectData={() => this.getDropDownStatusVal(this.state.project)}
          setShowConainerCModal={(value) => {
            this.setState({
              showContainerCModal: value,
            });
          }}
          details={this.getDetails(this.state.project)}
          getSavedChildPhaseResponse={this.getSavedChildPhaseResponse}
          reasonCodes={(this.state.reasonCodes || []).filter(r => r.active)}
        />
      </div>
    );
  }
}

export default ChildPhasePage;

