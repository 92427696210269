import React from "react";
import axios from "axios";
import FormStepper from "../stepper";
import { Formik, Form, Field } from "formik";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

export const Page3 = ({ formData, docId, step }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const downloadFile = () => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/docStorage/serveImage/${docId}`, {
        headers: { "Access-Control-Allow-Origin": "*"},
        responseType: 'blob'
      })
      .then((response) => {
        if (response?.data) {
          console.log(response.data);
          const url = window.URL.createObjectURL(
            new Blob([response.data]),
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            'download',
            'Letter.pdf',
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Formik initialValues={formData}>
        {({ values }) => (
          <Form className="parent-form">
            <FormStepper step={step} />
            <div className="parent-field-container">
              <div className="field-container">
                <div className="top-title-container">
                  <div className="big-title">
                    Generate Letter: Final Approval
                  </div>
                  <div className="small-title">PERS ID: {values.persId}</div>
                </div>

                <div className="success-lower-container">
                  <div>
                    <CheckCircleOutlineRoundedIcon className="the-green-check" />
                    <div className="success-header">
                      Final Approval Letter Created Successfully!
                    </div>
                    <div className="sub-success-header">
                      Your Final Approval letter has been added to the letter
                      details sections of the PERS page.
                    </div>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "16px",
                      }}
                    >
                      {/* <a
                        href={`${global.REACT_APP_API_ENDPOINT}/docStorage/serveImage/${docId}`}
                        target="_blank"
                        style={{ margin: "16px" }}
                      > */}
                      <Button
                        className="button"
                        style={{ margin: "16px" }}
                        onClick={downloadFile}
                      >
                        View Letter
                      </Button>
                      {/* </a> */}
                      {values.persType == "New" ? (
                        <Link
                          to={`/project/pers/${values.persId}`}
                          style={{ margin: "16px" }}
                          replace
                        >
                          <Button
                            className="cancel-modal-button"
                            onClick={() => {
                              setTimeout(() => {
                                window.location.reload();
                              }, 500);
                            }}
                          >
                            Close
                          </Button>
                        </Link>
                      ) : (
                        <Link
                          to={`/project/pers-establish/${values.persId}`}
                          style={{ margin: "16px" }}
                          replace
                        >
                          <Button
                            className="cancel-modal-button"
                            onClick={() => {
                              setTimeout(() => {
                                window.location.reload();
                              }, 500);
                            }}
                          >
                            Close
                          </Button>
                        </Link>
                      )}
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-cancel-container"></div>
          </Form>
        )}
      </Formik>
    </>
  );
};
