import React from 'react';
import Card from 'react-bootstrap/Card';
import './Comments.css';



const IndividualComment = ({ item }) => {

  return (
    <div className="comment-full-container">
      <div className="comment-image"></div>
      <div className="comment-container">
        <div className="comment-name-time">
          <div className="comment-name">{item.userName} </div>
          <div className="comment-time">{item.timestamp}</div>
        </div>
        <div className="comment-comment">{item.comments}</div>
      </div>
    </div>
  );
};


export default IndividualComment;