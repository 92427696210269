import React, { Component } from "react";
import axios from "axios";
import "./Profile.css";
import { Link } from "react-router-dom";
import { Nav, Navbar, Accordion, Card } from "react-bootstrap";
import { FaChevronLeft, FaPen, FaPaperPlane, FaSave } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { MdFormatLineSpacing } from "react-icons/md";
import ContextAwareToggle from "./ContextAwareToggle";
import Comments from "../Comments/Comments";
import Pdf from "../Pdf/Pdf";
import Eligibility from "../Eligibility/Eligibility";
import DropzoneModal from "../Dropzone/DropzoneModal";
import ErrorPage from "../../error-page";
import {
  Field as ProjectField,
  DatePicker as ProjectDatePicker,
  UpdatedField as UpdatedProjectField,
} from "../Pers-atoms";
import ChildPhaseList from "../ChildPhase/ChildPhaseList/ChildPhaseList";
import Restrictions from "../Restrictions/Restrictions";
import ProjectRestrictions from "../Restrictions/Project/RestrictionsProject";
import {
  isProjectRole,
  isProjectStandardRole,
  isLenderRole,
  isReadonlyRole,
  isTPORole,
  isInternal,
  isAdminRole
} from "../../redux/actions/userAction";
import { Grid, Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import RemoveIcon from "@material-ui/icons/Remove";
import ProjectPreRestrictions from "../Project/ProjectPreRestrictions";
import ProjectPreRestrictionsModal from "../Project/ProjectPreRestrictionsModal";
import ContainerCModal from "../Project/ContainerCModal";
import ConfirmModal from "../Project/confirm-modal";
import FannieMaeIcon from "../../images/icons/Shape@1x.svg";
import FeedbackRemoveIcon from "../../images/icons/feedback-remove@1x.svg";
import SuccessIcon from "../../images/icons/Success@1x.svg";
import TimerIcon from "../../images/icons/timer@1x.svg";
import WarningIcon from "../../images/icons/Warning@1x.svg";
import EditIcon from "../../images/icons/edit@1x.svg";
import DeleteModal from "../Project/delete-modal";
import LenderCertificationList from "../LenderCertificationList/LenderCertificationList";
import ApprovalConditions from "../ApprovalConditions/ApprovalConditions";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import FmActivityFeed from "../FmActivityFeed/FmActivityFeed";
import ApartmentIcon from "../../images/apartment_black_24dp-1@2x.svg";
import LayersBlackIcon from "../../images/layers_black_24dp@1x.svg";
import CriticalIcon from "../../images/Critical@2x.svg";
import NewCondoIcon from "../../images/newcondo-image.png";
import Group17Icon from "../../images/Group 17.svg";
import Group11Icon from "../../images/Group 11@1x.svg";
import Group34Icon from "../../images/Group-34.svg";
import AddCircleIcon from "../../images/add_circle@1x.svg";
import Success1Icon from "../../images/icons/Success-1@1x.svg";
import Warning1Icon from "../../images/icons/Warning-1@1x.svg";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getDays } from "../shared/services/SharedService";

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    right: "-13px",
    width: "2em",
    height: "3em",
    marginTop: "-3px",
    marginRight: "-0.71em",
    marginBottom: "4px",
  },
})(Tooltip);

class Profile extends Component {
  constructor(props) {
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    super(props);
    this.state = {
      project: [],
      projects: {},
      getComments: [],
      getPdf: [],
      getChildPhase: [],
      getStatus: [],
      getEligibility: [],
      eligilbilityList: [],
      types: [],
      phaseRestrictions: [],
      lenderData: [],
      userName: "",
      userName: passData && passData.user,
      comments: "",
      phaseId: "",
      phaseName: "",
      street: "",
      projectId: "",
      persId: null,
      picId: "",
      checked: true,
      isOpen: false,
      isEdit: false,
      apiErrProject: "",
      internalErrProject: false,
      projectError: "",
      legalNameError: "",
      totalUnitError: "",
      addressError: "",
      reviewStatus: "",
      isProjectInManualMode: null,
      commentCharCount: 0,
      collapseKey: "1",
      showAdd: false,
      showHideInfo: true,
      showHideRestrictions: false,
      activeTab: "pre",
      restrictionErr: "",
      showContainerCModal: false,
      formData: [],
      containsPers: false,
      lenderCertificationList: [],
      certificationCount: null,
      showDeleteModal: false,
      approvalConditions: [],
      showTooltip: false,
      fmActivity: [],
      disablePostButton: false,
      showConfirmModal: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.handleTip = this.handleTip.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
  }

  async componentDidMount() {
    this.fetchType();
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    let role = sessionStorage.getItem("role");
    if (role) {
      this.init(passData);
    } else {
      this.getUserDetails();
    }
  }

  async getUserDetails() {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/translatedRoleMap/`)
      .then((response) => {
        if (response.status == 200) {
          sessionStorage.setItem("roleData", JSON.stringify(response.data));
          if (!sessionStorage.getItem("role")) {
            sessionStorage.setItem("role", response.data.selectedRole);
          }
          const passData = JSON.parse(sessionStorage.getItem("passData"));
          this.init(passData);
        }
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  async init(passData) {
    this.getPhaseDetails(passData);
  }

  async getLenderViewPersDecisionConditionsForPhase(passData) {
    try {
      if (isLenderRole() || isTPORole()) {
        const passData = JSON.parse(sessionStorage.getItem("passData"));
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const { data: lenderApprovalConditions } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/lender/lenderViewPersDecisionConditionsForPhase/` +
            this.state.phaseId +
            `/` +
            +this.state.projectId +
            `/` +
            lenderId
        );
        this.setState({
          approvalConditions: lenderApprovalConditions,
        });
      } else {
        const { data: projectApprovalConditions } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/phase/getPersDecisionConditionsForPhase/` +
            this.state.projectId +
            `/` +
            this.state.phaseId
        );
        this.setState({
          approvalConditions: projectApprovalConditions,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getReviewStatuses(passData) {
    try {
      let projectId = this.state.projectId;
      let phaseId = this.state.phaseId;
      const { data: getStatus } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/getReviewStatuses/` +
          projectId +
          `/` +
          phaseId
      );
      this.setState({
        getStatus,
      });
      this.statusCheck();
    } catch (err) {
      console.log(err);
    }
  }

  async findAllChildPhasesForParentPhase(passData) {
    try {
      let phaseId = this.state.phaseId;
      const { data: getChildPhase } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/phase/findAllChildPhasesForParentPhase/` +
          phaseId
      );
      this.setState({
        getChildPhase,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async getListAllDocsByProjectAndPhaseId(passData) {
    try {
      let projectId = this.state.projectId;
      let phaseId = this.state.phaseId;
      const { data: getPdf } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/docStorage/listAllDocsByProjectAndPhaseId/` +
          projectId +
          `/` +
          phaseId
      );
      this.setState({
        getPdf,
      });
    } catch (err) {
      console.log(err);
    }
  }
  async getRulesEngineResult() {
    if (isProjectStandardRole()) {
      try {
        let projectId = this.state.projectId;
        let phaseId = this.state.phaseId;
        const { data: getEligibility } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/eligibility/rulesEngineResult/` +
            phaseId
        );
        this.setState({
          getEligibility,
        });
        this.eligiCheck();
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getCommentsByProjectAndPhase() {
    if(isProjectStandardRole() || isReadonlyRole()) {
      try {
        let projectId = this.state.projectId;
        let phaseId = this.state.phaseId;
        const { data: getComments } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/condoMgr/getCommentsByProjectAndPhase/` +
            projectId +
            `/` +
            phaseId
        );
        this.setState({
          getComments,
        });
        console.log(this.state.getComments);
      } catch (err) {
        console.log(err);
      }
    }
  }
  async getLenderViewPhase(passData) {
    try {
      const lenderId =
        sessionStorage.getItem("lenderId") ||
        passData.sellerServicerCoNo ||
        "12346";
      let projectId = this.state.projectId;
      let phaseId = this.state.phaseId;
      const { data: lenderData } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/lender/lenderViewPhase/` +
          phaseId +
          "/" +
          projectId +
          "/" +
          lenderId
      );
      this.setState({
        lenderData,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async checkPersExistForPhase(passData) { 
    try {
      let phaseId = this.state.phaseId;
      const { data: containsPers } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/phase/checkPersExistForPhase/` +
          phaseId
      );
      this.setState({
        containsPers,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async getLenderPhaseDeliveryRestrictions(passData) {
    try {
      let projectId = this.state.projectId;
      let phaseId = this.state.phaseId;
      let phaseRestrictions;
      if (isLenderRole() || isTPORole()) {
        const lenderId =
          sessionStorage.getItem("lenderId") ||
          passData.sellerServicerCoNo ||
          "12346";
        const { data: lenderPhaseRestrictions } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/delvyRestrictions/getLenderPhaseDeliveryRestrictions/` +
            projectId +
            `/` +
            phaseId +
            `/` +
            lenderId
        );
        this.setState({
          phaseRestrictions: lenderPhaseRestrictions,
        });
      } else {
        const { data: phaseRestrictions } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/delvyRestrictions/getPhaseDeliveryRestrictions/` +
            projectId +
            `/` +
            phaseId
        );
        this.setState({
          phaseRestrictions,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getPhaseManualDeliveryRestrictions(passData) {
    if (isProjectStandardRole()) {
      try {
        let projectId = this.state.projectId;
        let phaseId = this.state.phaseId;
        const { data: formData } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/delvyRestrictions/editPhaseManualDeliveryRestrictions/` +
            projectId +
            `/` +
            phaseId
        );
        this.setState({
          formData,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getActiveLenderCertificationsForPhase(passData) {
    if (isProjectRole()) {
      try {
        const { data: lenderCertificationList } = await axios.get(
          global.REACT_APP_API_ENDPOINT +
            `/phase/getActiveLenderCertificationsForPhase/` +
            this.state.phaseId
        );
        this.setState({
          lenderCertificationList,
        });
        this.setState({
          certificationCount: lenderCertificationList
            ? lenderCertificationList.length.toString()
            : 0,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getReasonCodes() {
    try {
      const { data } = await axios.get(global.REACT_APP_API_ENDPOINT + `/reasonCodes/getAllReasonCodes`);
      console.log(data);
      if ( data ) {
        this.setState({
          reasonCodes : data.sort((a, b) => a.reasonCode.localeCompare(b.reasonCode))
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async getPhaseDetails(passData) {
    try {
      let id = this.props.match.params.project_id;
      const { data: project, data: projects } = await axios.get(
        global.REACT_APP_API_ENDPOINT + `/phase/viewPhase/` + id
      );
      this.setState({
        project,
        projects,
      });
      this.addProject();
      await Promise.all([
        this.fetchShowDeleteBox(),
        this.getActiveLenderCertificationsForPhase(passData),
        this.getPhaseManualDeliveryRestrictions(passData),
        this.getLenderPhaseDeliveryRestrictions(passData),
        this.checkPersExistForPhase(passData),
        this.getLenderViewPhase(passData),
        this.getCommentsByProjectAndPhase(passData),
        this.getRulesEngineResult(passData),
        this.getListAllDocsByProjectAndPhaseId(passData),
        this.findAllChildPhasesForParentPhase(passData),
        this.getReviewStatuses(passData),
        this.getLenderViewPersDecisionConditionsForPhase(passData),
        this.getReasonCodes(),
        this.fetchFMActivity()
      ]);
      
    } catch (err) {
      console.log(err);
    }
  }

  async fetchShowDeleteBox() {
    if (
      isProjectStandardRole() &&
      this.state.project.projectType === "New Condo"
    ) {
    const { projectId, phaseId } = this.state;
    try {
      const { data } = await axios.get(
        `${global.REACT_APP_API_ENDPOINT}/phase/showDeleteButtonForPhase/${phaseId}/${projectId}`
      );
      this.setState({
        showDeleteModal: data || false,
      });
    } catch (e) {
      console.log(e);
    }
  }
  }

  async fetchFMActivity() {
    try {
      const { data } = await axios.get(
        global.REACT_APP_API_ENDPOINT +
          `/activityFeed/getFnmaReviewStatusActivityFeedForPhase/${this.state.projectId}/${this.state.phaseId}`
      );
      this.setState({ fmActivity: data.activityFeedFnmaStatusDTO || [] });
      console.log("********************", data);
    } catch (error) {
      throw Error(error);
    }
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      disablePostButton: true,
    });
    axios
      .post(
        global.REACT_APP_API_ENDPOINT + `/condoMgr/postComments/`,
        this.state
      )
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  statusHandler = (e) => {
    e.preventDefault();
    axios
      .post(global.REACT_APP_API_ENDPOINT + `/condoMgr/toggleMode`, this.state)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    window.location.reload(false);
    console.log(this.state);
  };

  addProject() {
    this.setState({
      projects: this.state.projects,
      street: this.state.projects.street,
      phaseName: this.state.projects.phaseName,
      phaseId: this.state.projects.phaseId,
      projectId: this.state.projects.projectId,
      picId: this.state.projects.mainPicId,
    });
  }

  statusCheck() {
    this.setState({
      isProjectInManualMode: this.state.getStatus.autoModeFlag,
    });
  }

  eligiCheck() {
    this.setState({
      eligilbilityList: this.state.getEligibility.ruleExecutionResultDetailsDTO,
    });
  }

  renderDecision() {
    let list = <p>Loading...</p>;
    if (this.state.phaseRestrictions.phaseDR) {
      list = <Restrictions list={this.state.phaseRestrictions.phaseDR} />;
    }
    return list;
  }

  renderDecision2() {
    let list = <p>Loading...</p>;
    if (this.state.phaseRestrictions.projectDR) {
      list = (
        <ProjectRestrictions list={this.state.phaseRestrictions.projectDR} />
      );
    }
    return list;
  }

  renderComments() {
    let list = <h1>Loading...</h1>;
    if (this.state.getComments) {
      list = <Comments list={this.state.getComments} />;
    }
    return list;
  }

  renderPdf() {
    let list = <h1>Loading...</h1>;
    if (this.state.getPdf) {
      list = <Pdf list={this.state.getPdf} />;
    }
    return list;
  }

  renderChildPhase() {
    let list = <h1>Loading...</h1>;
    if (this.state.getChildPhase) {
      list = <ChildPhaseList list={this.state.getChildPhase} />;
    }
    return list;
  }

  renderEligibility() {
    let list = <h1></h1>;
    if (this.state.eligilbilityList) {
      list = <Eligibility list={this.state.eligilbilityList} />;
    }
    return list;
  }

  handleChange(checked) {
    this.setState({ checked: !this.state.getStatus.autoModeFlag });
  }

  collapseAll() {
    if (this.state.collapseKey === "1") {
      this.setState((state) => ({
        collapseKey: "0",
      }));
    } else if (this.state.collapseKey === "0") {
      this.setState((state) => ({
        collapseKey: "1",
      }));
    }
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () =>
    this.setState({
      isOpen: false,
      checked: !this.state.getStatus.autoModeFlag,
    });
  confirmModal = () => this.setState({ isOpen: false });

  toggleEdit = (flag) => {
    this.setState({
      isEdit: flag,
    });
    if (!flag) {
      this.setState({
        projectError: "",
      });
      // this.getData();
    }
  };

  handleChange = (event) => {
    this.state.project[event.target.name] = event.target.value;
    this.setState({
      project: this.state.project,
    });
  };

  handleSaveEdit = async () => {
    console.log(this.state.project, "from  save edit");
    window.sessionStorage.setItem(
      "project",
      JSON.stringify(this.state.project)
    );
    if (this.state.project.phaseName == "") {
      this.setState({
        legalNameError: "This field is required",
      });
    } else {
      this.setState({
        legalNameError: "",
      });
    }

    const regexUnit = new RegExp(/^[0-9]*$/);
    if (this.state.project.unitCount.length == 0) {
      this.setState({
        totalUnitError: "This field is required",
      });
    } else if (this.state.project.unitCount <= 0) {
      this.setState({
        totalUnitError: "Number of units cannot be 0 or negative",
      });
    } else if (regexUnit.test(this.state.project.unitCount) === false) {
      this.setState({
        totalUnitError: "Invalid Unit Number",
      });
    } else {
      this.setState({
        totalUnitError: "",
      });
    }

    if (this.state.project.street == "") {
      this.setState({
        addressError: "This field is required",
      });
    } else {
      this.setState({
        addressError: "",
      });
    }

    if (
      this.state.project.projectName !== "" &&
      this.state.projectError == "" &&
      this.state.project.unitCount > 0 &&
      this.state.project.street !== ""
    ) {
      this.setState({
        showConfirmModal: true,
      });
    }
  };

  getData() {
    // try {
    let id = this.props.match.params.project_id;
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/phase/viewPhase/` + id)
      .then((response) => {
        console.log("success update", response);
        window.sessionStorage.setItem("project", JSON.stringify(response.data));

        this.setState({
          project: response.data,
        });
        this.addProject();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchType() {
    const response = axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/PHASE_TYPE`)
      .then((response) => {
        const typesResponse = response.data;
        const types = [];
        for (const type in typesResponse) {
          types.push({
            key: typesResponse[type],
            value: typesResponse[type],
          });
        }
        //types.unshift({ key: "", value: "Select Style" });
        this.setState({
          types: types,
        });
      })

      .catch((error) => {
        throw Error(error);
      });
  }

  saveDataAPI(project) {
    console.log("save API call");
    const passData = JSON.parse(sessionStorage.getItem("passData"));
    this.setState({
      apiErrProject: "",
      internalErrProject: false,
    });

    project.userName = passData.user;

    axios
      .post(global.REACT_APP_API_ENDPOINT + `/phase/editPhase`, project)
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            isEdit: false,
            apiErrProject: "",
            internalErrProject: false,
          });
          this.getData();
          this.submitHandler(null);
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            this.setState({
              internalErrProject: true,
              apiErrProject: "",
              isEdit: false,
            });
            // window.location.href = "/error-page";
          } else {
            this.setState({
              apiErrProject: response.data.message,
            });
          }
        }
        // window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillMount() {
    window.sessionStorage.removeItem("project");
  }
  getProjectPhaseType = () => {
    const project = this.state.project;
    if (
      window.sessionStorage.getItem("project") &&
      JSON.parse(window.sessionStorage.getItem("project"))
    ) {
      console.log("from session storage");
      const projectPhaseType =
        JSON.parse(window.sessionStorage.getItem("project")) &&
        JSON.parse(window.sessionStorage.getItem("project"));
      return projectPhaseType;
    } else {
      console.log(project, project.hasOwnProperty("phaseType"));
      console.log(JSON.stringify(project));
      const stringifiedProject = JSON.stringify(project);
      console.log(project.length);
      if (project.hasOwnProperty("phaseType")) {
        console.log(project);

        window.sessionStorage.setItem("project", stringifiedProject);
      }
      // window.sessionStorage.setItem('project',JSON.stringify(this.state.project))
      return this.state.project;
    }
  };

  handleSaveRestrictions = async () => {
    const passData = JSON.parse(sessionStorage.getItem("passData"));

    let userId = passData.user;

    let preConSelect = [];
    if (this.state.formData.preCannedDR) {
      preConSelect = this.state.formData.preCannedDR.filter(
        (c) => c.isExistingRestrictions
      );
    }

    let customConSelect = [];
    if (this.state.formData.customDR) {
      customConSelect = this.state.formData.customDR.filter(
        (c) => c.isExistingRestrictions
      );
    }

    let addedConSelect = [];
    if (this.state.formData.addedRestrictions) {
      addedConSelect = this.state.formData.addedRestrictions.map(
        ({ restrictionsName, restrictionsDescription }) => ({
          restrictionsName,
          restrictionsDescription,
          isAddedRestrictions: true,
        })
      );
    }

    this.setState({
      apiErrRestrictions: "",
      internalErrRestrictions: false,
    });

    this.setState({ restrictionErr: "" });
    const comboSelect = preConSelect.concat(addedConSelect, customConSelect);
    const entirePhaseData = {
      projectId: this.state.project.projectId,
      phaseId: this.state.phaseId,
      createdById: userId,
      manRestrictions: comboSelect,
    };
    axios
      .post(
        global.REACT_APP_API_ENDPOINT +
          `/delvyRestrictions/savePhaseManualDeliveryRestrictions/`,
        entirePhaseData
      )
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            isEdit: false,
            apiErrRestrictions: "",
            internalErrRestrictions: false,
          });
          this.getData();
        } else if (response.data && response.data.message) {
          if (response.data.message == "Internal server error") {
            this.setState({
              internalErrRestrictions: true,
              apiErrRestrictions: "",
              isEdit: false,
            });
            // window.location.href = "/error-page";
          } else {
            this.setState({
              apiErrRestrictions: response.data.message,
            });
          }
        }
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  hideComponent(name) {
    switch (name) {
      case "showHideInfo":
        this.setState({ showHideInfo: !this.state.showHideInfo });
        this.setState({
          showHideRestrictions: !this.state.showHideRestrictions,
        });
        break;
      case "showHideRestrictions":
        this.setState({
          showHideRestrictions: !this.state.showHideRestrictions,
        });
        break;
      default:
        break;
    }
  }

  getProjectDetailStatus = (projectData) => {
    if (projectData) {
      // projectData.projectProfile.fnmaReviewStatus
      switch (projectData.fnmaReviewStatus) {
        case "Approved By Fannie Mae":
          return {
            icon: <img alt="Success Icon" src={SuccessIcon} />,
            name: "Approved By Fannie Mae",
          };
        case "Pending PERS Review":
          return {
            icon: <img alt="Timer Icon" src={TimerIcon} />,
            name: "Pending PERS Review",
          };
        case "Conditional Approval":
          return {
            icon: <img alt="Warning Icon" src={WarningIcon} />,
            name: "Conditional Approval",
          };
        case "Unavailable":
          return {
            icon: <img alt="Removal Icon" src={FeedbackRemoveIcon} />,
            name: "Unavailable",
          };
        case "Fannie Mae Approval Expired":
          return {
            icon: <img alt="Alternate Success Icon" src={Success1Icon} />,
            name: "Fannie Mae Approval Expired",
          };
        case "Fannie Mae Conditional Approval Expired":
          return {
            icon: <img alt="Alternate Warning Icon" src={Warning1Icon} />,
            name: "Conditional Approval Expired",
          };
        default:
          return { icon: null, name: "No Fannie Mae Review" };
      }
    }
  };

  renderExpiry = (data, isLender = false) => {
    return (
      <>
        {this.getExpiry(data, isLender) ? (
          <div style={{ display: "flex" }}>
            <div>Expiration: </div>
            <div>
              <span style={{ fontWeight: "bold", paddingLeft: "5px" }}>
              {
                this.getFmStatus(isLender) === 'Approved By Fannie Mae' && getDays(this.getExpiry(data, isLender)) <= 120 &&
                <CustomTooltip title={"Expires in "+  getDays(this.getExpiry(data, isLender)) + " days" }  placement="bottom"><AccessTimeIcon className="clock" /></CustomTooltip>
              }
                {this.getExpiry(data, isLender)}
              </span>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  getFmStatus = (isLender) => {
    if (isLender) {
      return this.state.lenderData.fnmaReviewStatus;
    } else {
      return this.state.project.fnmaReviewStatus;
    }

  }
  showMannualOverrideButton = (projectData) => {
    if (projectData) {
      if(projectData.showManualOverrideButton 
        && isAdminRole() && this.state.containsPers) {
        return false;
      }
      return projectData.showManualOverrideButton;
    }
  };

  getExpiry = (projectData, isLender) => {
    if (isLender) {
      return projectData.fnmaReviewStatusExpyDate;
    }
    if (projectData) {
      return projectData.fnmaReviewStatusDtExpiry;
    }
  };

  getDropDownStatusVal = (projectData) => {
    if (projectData) {
      console.log(
        "inside getdropdownval $$$$   ",
        projectData.manualOverrideCanChangeTo
      );
      return projectData.manualOverrideCanChangeTo;
    }
  };

  getDetails = (data) => {
    return {
      type: "phase",
      projectId: data.projectId,
      phaseId: data.phaseId,
      fnmaReviewStatus: data.fnmaReviewStatus,
    };
  };
  getSavedPhaseResponse = (response) => {
    console.log("savedres", response);
    this.setState({
      project: {
        ...this.state.project,
        fnmaReviewStatus: response.fnmaReviewStatus,
        showManualOverrideButton: response.showManualOverrideButton,
        fnmaReviewStatusDtExpiry: response.approvalExpiryDate
      },
      showContainerCModal: false,
    });
  };

  renderLenderCertification() {
    let list = <h1>Loading...</h1>;
    if (this.state.lenderCertificationList) {
      list = (
        <LenderCertificationList list={this.state.lenderCertificationList} />
      );
    }
    return list;
  }

  renderTooltipTitle() {
    return (
      <div style={{ lineHeight: 1.5, fontSize: 14 }}>
        <span
          style={{
            fontSize: 18,
            fontFamily: "Source Sans Pro",
            fontWeight: 600,
          }}
        >
          {" "}
          Don't see the phase(s) you are looking for?{" "}
        </span>
        <br />
        {
          "Provide the following: amendment or annexation for requested phase, and HOA Questionnaire. Send to"
        }
        <p style={{ color: "blue" }}>CPM_Manage@fanniemae.com</p>
      </div>
    );
  }
  renderFmActivity() {
    const { fmActivity } = this.state;
    let list = <h1>Loading...</h1>;
    if (fmActivity) {
      //consider moving this to a component when doing the fm activity feed for phase and childphase
      list = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          {fmActivity.map((ele, index) => (
            <FmActivityFeed ele={ele} index={index} reasonCodes={this.state.reasonCodes}  />
          ))}
        </div>
      );
    }
    return list;
  }
  renderTooltipRest() {
    return (
      <div style={{ lineHeight: 1.5, fontSize: 14 }}>
        <span style={{ color: "#1c6fa3", width: 16, height: 16 }}>
          {" "}
          <InfoIcon />
        </span>
        <br />
        <div
          style={{
            color: "rgba(0,0,0,0.82)",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            letterSpacing: "0",
            lineHeight: "24px",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          There is a PERS associated to this Phase. Please use the PERS decision
          workflow to edit Delivery Restrictions.
        </div>
      </div>
    );
  }

  handleTip() {
    const val = this.state.showTooltip;
    this.setState({
      showTooltip: !val,
    });
  }

  getSaveConfirmation = (response) => {
    this.setState({
      comments: response,
      commentCharCount: response.length,
      showConfirmModal: false,
    });
    console.log(this.state);
    this.saveDataAPI(this.state.project);
  };

  render() {
    const { comments, showHideInfo, showHideRestrictions } = this.state;
    console.log("renderfunction ###", this.state);
    // window.onscroll = function() {
    //   scrollFunction();
    // };

    const setRestrictionsData = (_restrictions, name) => {
      const _formData = { ...this.state.formData, [name]: [..._restrictions] };
      this.setState({ formData: _formData });
    };
    const handleAddClose = () => this.setState({ showAdd: false });
    const handleAddClick = (newRestriction) => {
      const _formData = {
        ...this.state.formData,
        addedRestrictions: [
          ...(this.state.formData.addedRestrictions || []),
          { ...newRestriction },
        ],
      };
      this.setState({ formData: _formData });
      this.setState({ showAdd: false });
    };
    const handleAddShow = () => this.setState({ showAdd: true });
    const changeTab = (params) => {
      this.setState({ activeTab: params });
    };
    const { addedRestrictions = [], restrictions = [] } = this.state.formData;
    


    return (
      <div>
        <div
          className="title-container sticky-top"
          style={{ marginLeft: "0px", paddingLeft: "78px" }}
        >
          <div>
            <a className="update-cookie-dash" href={"/"}>
              <HomeIcon
                style={{
                  fontSize: "1rem",
                  marginRight: "10px",
                  marginBottom: "4px",
                  color: "#0A649D",
                }}
              />
              Home
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            <a
              className="update-cookie-dash"
              href={`/project/${this.state.project.projectId}`}
            >
              <img
                alt="Apartment Icon"
                style={{
                  marginRight: "10px",
                  marginBottom: "4px",
                  width: "17px",
                  height: "17px",
                }}
                src={ApartmentIcon}
              />
              {this.state.project.projectName}
            </a>{" "}
            <RemoveIcon style={{ marginLeft: "8px", marginRight: "8px" }} />
            <a className="update-cookie-dash">
              <img
                alt="Phase Icon"
                style={{
                  marginRight: "10px",
                  marginBottom: "4px",
                  width: "17px",
                  height: "17px",
                }}
                src={LayersBlackIcon}
              />
              {this.state.phaseName}
            </a>
          </div>
          <p className="update-project-name">{this.state.phaseName}</p>
          <p className="project-id-container">
            <img
              alt="Phase Icon"
              style={{ marginRight: "10px", marginBottom: "4px" }}
              src={LayersBlackIcon}
            />
            Phase ID: {this.state.phaseId}
          </p>
        </div>

        <div
          id="project-profile-top-phase"
          className="project-profile-top-phase"
        >
          {typeof this.state.phaseRestrictions.phaseDR !== "undefined" ? (
            <>
              {this.state.phaseRestrictions.phaseDR.length == 0 &&
              this.state.phaseRestrictions.projectDR.length == 0 ? (
                <div
                  id="item-e-phase"
                  className="item-e-phase"
                  style={{ all: "unset" }}
                ></div>
              ) : (
                <div id="item-e-phase" className="item-e-phase">
                  <div className="item-e-title">
                    <img
                      alt="Critical Warning Icon"
                      className="item-e-image"
                      src={CriticalIcon}
                    />
                    Delivery Restrictions
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          <div id="item-a-phase" className="item-a-phase">
            <div className="child-image-pill">
              <div className="image-pill-date">
                {this.state.project.projectName}
              </div>
              <div className="image-pill-type">
                {this.state.project.phaseType} PHASE
              </div>
            </div>
            <img
              alt="New Condo Image"
              className="center-image"
              src={NewCondoIcon}
            />
          </div>
          <div id="item-b-phase" className="item-b-phase">
            <div className="item-b-container">
              <div className="item-b-container-top">
                {/* <img src={Group17Icon} /> */}
                <img
                  alt="Map Icon"
                  src={Group17Icon}
                  height="47px"
                  style={{ height: "47px" }}
                />
              </div>
              <div className="item-b-container-bottom">
                <div className="top">Phase Address:</div>
                <div className="bottom">
                  {this.state.street != "" ? (
                    <>
                      <div>{this.state.street}</div>
                      <div>
                        {this.state.project.city}, {this.state.project.state}{" "}
                        {this.state.project.zip5}
                      </div>
                    </>
                  ) : (
                    <div>Not Available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="item-c-phase" className="item-c-phase">
            <div className="item-c-container">
              <div className="item-c-container-top">
                {this.showMannualOverrideButton(this.state.project) &&
                  isInternal() && (
                    <img
                      alt="Edit Pencil Icon"
                      src={EditIcon}
                      className="item-c-container-top-edit"
                      onClick={() => {
                        this.setState({
                          showContainerCModal: true,
                        });
                      }}
                    />
                  )}
                <img
                  alt="Fannie Mae Icon"
                  src={FannieMaeIcon}
                  style={{ marginRight: "13px" }}
                />
                {isLenderRole() || isTPORole()
                  ? this.getProjectDetailStatus(this.state.lenderData) &&
                    this.getProjectDetailStatus(this.state.lenderData).icon
                  : this.getProjectDetailStatus(this.state.project) &&
                    this.getProjectDetailStatus(this.state.project).icon}
              </div>
              <div className="item-c-container-bottom">
                <div className="top">Fannie Mae Review Status </div>
                <div className="bottom">
                  {isLenderRole() || isTPORole() ? (
                    <div>
                      {this.getProjectDetailStatus(this.state.lenderData) &&
                        this.getProjectDetailStatus(this.state.lenderData).name}
                    </div>
                  ) : (
                    <div>
                      {this.getProjectDetailStatus(this.state.project) &&
                        this.getProjectDetailStatus(this.state.project).name}
                    </div>
                  )}
                </div>
                {isLenderRole() || isTPORole()
                  ? this.renderExpiry(this.state.lenderData, true)
                  : this.renderExpiry(this.state.project)}
              </div>
            </div>
          </div>
          {isLenderRole() || isTPORole()? (
            <div id="item-d-phase" className="item-d-phase">
              <div className="item-d-container" style={{ maxWidth: "253px" }}>
                <div
                  className="item-d-container-top"
                  style={{ padding: "18px 24px 18px 24px" }}
                >
                  <img alt="Certification Icon" src={Group11Icon} />
                  {this.state.lenderData.showCertifyButton === true &&
                  !isReadonlyRole() ? (
                    <a
                      href={`/certification/${this.state.projectId}/${
                        this.state.phaseId
                      }/${
                        this.state.lenderData.lenderCertificationStatus ===
                        "Incomplete"
                          ? "Incomplete"
                          : "new"
                      }`}
                    >
                      <button
                        className="item-d-container-button"
                        style={{ color: "rgba(255,255,255,0.82)" }}
                      >
                        Certify Phase
                      </button>
                    </a>
                  ) : this.state.lenderData.showViewCertificationButton ===
                    true ? (
                    <a
                      href={`/view-certification/${this.state.projectId}/${this.state.phaseId}/new`}
                    >
                      <button className="item-d-container-button">
                        View Certification
                      </button>
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="item-d-container-bottom">
                  <div className="top" style={{ fontSize: "16px" }}>
                    Lender Certification Status
                  </div>
                  <div className="bottom">
                    <div>{this.state.lenderData.lenderCertificationStatus}</div>
                  </div>
                  {this.state.lenderData.lenderCertExpiryDate !== null ? (
                    <div style={{ display: "flex" }}>
                      <div>Expiration: </div>
                      <div>
                        <span
                          style={{ fontWeight: "bold", paddingLeft: "5px" }}
                        >
                          {this.state.lenderData.lenderCertExpiryDate}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : isProjectRole() ? (
            <div id="item-d-phase" className="item-d-phase">
              <div className="item-d-container" style={{ maxWidth: "253px" }}>
                <div
                  className="item-d-container-top"
                  style={{ padding: "18px 24px 18px 24px" }}
                >
                  <img alt="Certification Icon" src={Group11Icon} />
                </div>
                <div className="item-d-container-bottom">
                  <div className="top" style={{ fontSize: "16px" }}>
                    Lender Certification Status
                  </div>
                  {this.state.certificationCount ? (
                    <div className="bottom">
                      <div>
                        {this.state.certificationCount} Lender Certifications{" "}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div id="item-a-sticky" className="item-a-sticky"></div>
          <div id="item-b-sticky" className="item-b-sticky">
            <img alt="Orange Timer Icon" src={Group34Icon} />
            <div className="status"></div>
            <div className="prompt"></div>
            <div className="value"></div>
            <div className="prompt">Phase ID:</div>
            <div className="value">{this.state.project.phaseId}</div>
            <div className="button-container"></div>
          </div>
        </div>
        <Navbar className="project-nav" sticky="top">
          <Nav className="container-fluid">
            <Nav.Link href="#section0" className="profile-nav-button">
              Phase Information
            </Nav.Link>
            <Nav.Link
              href="#section1"
              className="profile-nav-button"
              onClick={this.handleNavClick}
            >
              Fannie Mae Activity Feed
            </Nav.Link>
            <Nav.Link href="#section2" className="profile-nav-button">
              Construction/Marketing Phases
            </Nav.Link>
            {isProjectRole() ? (
              <>
                <Nav.Link href="#section3" className="profile-nav-button">
                  Attachments
                </Nav.Link>
                <Nav.Link href="#section4" className="profile-nav-button">
                  Comments
                </Nav.Link>
              </>
            ) : (
              <></>
            )}
            {isProjectRole() &&
            this.state.lenderCertificationList.length !== 0 ? (
              <Nav.Link
                href="#section7"
                className="profile-nav-button"
                onClick={this.handleNavClick}
              >
                Lender Certification
              </Nav.Link>
            ) : (
              <></>
            )}
            <div className="ml-auto">
              {/* {isLenderRole() ? (
                <CustomTooltip
                  style={{ backgroundColor: "white" }}
                  title={this.renderTooltipTitle()}
                  placement="left"
                  arrow
                  open={this.state.showTooltip}
                  onClose={this.handleTip}
                  onOpen={this.handleTip}
                >
                  <span
                    id="request-phase"
                    style={{
                      cursor: "pointer",
                      color: "#1c6fa3",
                      fontSize: 14,
                    }}
                  >
                    <InfoIcon style={{ width: 20, height: 18 }} /> Need to
                    request a new phase?
                  </span>
                </CustomTooltip>
              ) : (
                ""
              )} */}
            </div>
          </Nav>
        </Navbar>
        <button className="collapse-all" onClick={this.collapseAll}>
          <MdFormatLineSpacing className="collapse-all-icon" />
          Collapse All
        </button>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section0"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">Phase Information</p>
              </ContextAwareToggle>

              {isProjectStandardRole() &&
              this.state.project.projectType === "New Condo" ? (
                this.state.isEdit ? (
                  <>
                    {this.state.showDeleteModal ? (
                      <DeleteModal
                        type="phase"
                        projectvalue={this.state.projectId}
                        phasevalue={this.state.phaseId}
                      />
                    ) : (
                      ""
                    )}
                    <div>
                      <button
                        className="cancel-button"
                        onClick={() => {
                          this.toggleEdit(false);
                          this.setState({
                            apiErrProject: false,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        //   disabled
                        className="add-phase-button"
                        onClick={this.handleSaveEdit}
                      >
                        <FaSave className="edit-profile-icon" /> Save Edits
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    disabled={this.state.showHideRestrictions === true}
                    className="add-phase-button"
                    onClick={() => {
                      this.toggleEdit(true);
                      this.setState({
                        internalErrProject: false,
                        apiErrProject: "",
                      });
                    }}
                  >
                    <FaPen className="edit-profile-icon" /> Edit Section
                  </button>
                )
              ) : null}
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-grid-body-card">
                <div
                  style={{
                    textAlign: "right",
                    color: "#ff0000",
                    padding: 15,
                  }}
                ></div>
                <div className="accordion-grid-parent">
                  {this.state.phaseRestrictions.showAddManualRestrictions ===
                    true &&
                  this.state.phaseRestrictions.projectDR.length === 0 &&
                  this.state.phaseRestrictions.phaseDR.length === 0 ? (
                    <div className="bigbutton">
                      <div
                        onClick={() => this.hideComponent("showHideInfo")}
                        className="add-restrictions-button"
                      >
                        <img
                          alt="Add Icon"
                          className="button-image"
                          src={AddCircleIcon}
                        />
                        Add Delivery Restrictions
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {showHideInfo && (
                    <>
                      <div className="header">
                        <div className="title">Overview</div>
                      </div>
                      <div className="attributes">
                        <div className="attri-container">
                          <div className="attri-title">
                            Phase Name{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </div>
                          {this.state.isEdit ? (
                            <div className="attri-value">
                              <input
                                required
                                aria-label="Phase Name"
                                type="text"
                                className="form-control"
                                name="phaseName"
                                value={this.state.project.phaseName}
                                maxLength="300"
                                onChange={(event) => {
                                  this.handleChange(event);
                                  if (event.target.value.length == 0) {
                                    this.setState({
                                      projectError: "This field is required",
                                    });
                                  } else {
                                    this.setState({
                                      projectError: "",
                                    });
                                  }
                                }}
                              />

                              <div style={{ color: "#ff0000" }}>
                                {this.state.projectError}
                              </div>
                            </div>
                          ) : (
                            <div className="attri-value">
                              {this.state.project.phaseName}{" "}
                            </div>
                          )}
                        </div>

                        <div className="attri-container">
                          <div className="attri-title">Legacy CPM ID</div>
                          {this.state.isEdit ? (
                            <div className="attri-value">
                              <input
                                required
                                aria-label="Legacy CPM ID"
                                type="text"
                                className="form-control"
                                name="cpmId"
                                value={this.state.project.cpmId}
                                maxLength="16"
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="attri-value">
                              {this.state.project.cpmId}{" "}
                            </div>
                          )}
                        </div>

                        <UpdatedProjectField
                          label="Phase Type"
                          disabled={
                            this.state.getChildPhase.length &&
                            this.getProjectPhaseType().phaseType === "Legal"
                          }
                          name="phaseType"
                          formData={this.state.project}
                          handleChange={this.handleChange}
                          isEdit={this.state.isEdit}
                          dropdown={true}
                          dropdownValues={this.state.types}
                        />

                        <div className="attri-container">
                          <div className="attri-title">
                            Number of Units in Phase{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </div>
                          {this.state.isEdit ? (
                            <div className="attri-value">
                              <input
                                required
                                aria-label="Number of Units in Phase"
                                type="text"
                                className="form-control"
                                name="unitCount"
                                min="0"
                                maxLength="64"
                                value={this.state.project.unitCount}
                                onChange={(event) => {
                                  this.handleChange(event);
                                  const regex = new RegExp(/^[0-9]*$/);
                                  if (event.target.value.length == 0) {
                                    this.setState({
                                      totalUnitError: "This field is required",
                                    });
                                  } else if (event.target.value <= 0) {
                                    this.setState({
                                      totalUnitError:
                                        "Number of units cannot be 0 or negative",
                                    });
                                  } else if (
                                    regex.test(event.target.value) === false
                                  ) {
                                    this.setState({
                                      totalUnitError: "Invalid Unit Number",
                                    });
                                  } else {
                                    this.setState({
                                      totalUnitError: "",
                                    });
                                  }
                                }}
                              />
                              <div style={{ color: "#ff0000" }}>
                                {this.state.totalUnitError
                                  ? this.state.totalUnitError
                                  : null}
                              </div>
                            </div>
                          ) : (
                            <div className="attri-value">
                              {this.state.project.unitCount}
                            </div>
                          )}
                        </div>

                        <div className="attri-container">
                          <div className="attri-title">Building Number</div>
                          {this.state.isEdit ? (
                            <div className="attri-value">
                              <input
                                type="text"
                                aria-label="Building Number"
                                className="form-control"
                                name="buildingNumber"
                                value={this.state.project.buildingNumber}
                                maxLength="100"
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="attri-value">
                              {this.state.project.buildingNumber}{" "}
                            </div>
                          )}
                        </div>

                        <div className="attri-container">
                          <div className="attri-title">Unit Number</div>
                          {this.state.isEdit ? (
                            <div className="attri-value">
                              <input
                                type="text"
                                aria-label="Unit Number"
                                className="form-control"
                                name="unitNumber"
                                value={this.state.project.unitNumber}
                                maxLength="100"
                                onChange={(event) => {
                                  this.handleChange(event);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="attri-value">
                              {this.state.project.unitNumber}{" "}
                            </div>
                          )}
                        </div>
                        <div className="attri-container">
                          <div className="attri-title">
                            Phase Address{" "}
                            <span style={{ color: "#ff0000" }}>*</span>
                          </div>
                          {this.state.isEdit ? (
                            <div className="attri-value">
                              <input
                                required
                                aria-label="Phase Address"
                                type="text"
                                className="form-control"
                                name="street"
                                value={this.state.project.street}
                                maxLength="256"
                                onChange={(event) => {
                                  this.handleChange(event);
                                  if (event.target.value.length == 0) {
                                    this.setState({
                                      addressError: "This field is required",
                                    });
                                  } else {
                                    this.setState({
                                      addressError: "",
                                    });
                                  }
                                }}
                              />

                              <div style={{ color: "#ff0000" }}>
                                {this.state.addressError}
                              </div>
                            </div>
                          ) : (
                            <div className="attri-value">
                              {this.state.project.street}{" "}
                            </div>
                          )}
                        </div>

                        <div className="attri-container">
                          <div className="attri-title">
                            City <span style={{ color: "#ff0000" }}>*</span>
                          </div>
                          <div className="attri-value">
                            {this.state.project.city}{" "}
                          </div>
                        </div>

                        <div className="attri-container">
                          <div className="attri-title">
                            State <span style={{ color: "#ff0000" }}>*</span>
                          </div>
                          <div className="attri-value">
                            {this.state.project.state}{" "}
                          </div>
                        </div>

                        <div className="attri-container">
                          <div className="attri-title">
                            Zip Code <span style={{ color: "#ff0000" }}>*</span>
                          </div>
                          <div className="attri-value">
                            {this.state.project.zip5}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="restrictions">
                        {typeof this.state.phaseRestrictions.phaseDR !==
                        "undefined" ? (
                          <>
                            {this.state.phaseRestrictions.phaseDR.length == 0 &&
                            this.state.phaseRestrictions.projectDR.length ==
                              0 ? (
                              <div></div>
                            ) : (
                              <div className="dlvy-restrictions">
                                <div className="restrictions-red-parent">
                                  <div className="restrictions-title">
                                    <img
                                      alt="Critical Warning Icon"
                                      className="restrictions-image"
                                      src={CriticalIcon}
                                    />
                                    Delivery Restrictions{" "}
                                    {!isReadonlyRole() &&
                                    this.state.phaseRestrictions
                                      .showAddManualRestrictions === true &&
                                    this.state.project.projectType ===
                                      "New Condo" ? (
                                      <div
                                        style={{
                                          float: "right",
                                          cursor: "pointer",
                                          paddingRight: "11.5px",
                                        }}
                                        onClick={() =>
                                          this.hideComponent("showHideInfo")
                                        }
                                      >
                                        <FaPen
                                          style={{ color: "#1176b5" }}
                                          className="edit-profile-icon"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          float: "right",
                                          cursor: "pointer",
                                          paddingRight: "11.5px",
                                        }}
                                      >
                                        <CustomTooltip
                                          style={{
                                            backgroundColor: "white",
                                          }}
                                          title={this.renderTooltipRest()}
                                          placement="left"
                                          arrow
                                        >
                                          <span
                                            id="request-phase"
                                            style={{
                                              cursor: "pointer",
                                              color: "#1c6fa3",
                                              fontSize: 14,
                                            }}
                                          >
                                            <InfoIcon
                                              style={{
                                                width: 20,
                                                height: 18,
                                                marginBottom: "3px",
                                              }}
                                            />
                                          </span>
                                        </CustomTooltip>
                                      </div>
                                    )}
                                  </div>
                                  <div className="project-phase-restrictions">
                                    {this.renderDecision2()}
                                  </div>
                                  <div className="project-phase-restrictions">
                                    {this.renderDecision()}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {!isTPORole() && typeof this.state.approvalConditions &&
                        (this.state.approvalConditions.projectCondnList ||
                          this.state.approvalConditions.phaseCondnList) ? (
                          <>
                            {this.state.approvalConditions.projectCondnList &&
                            this.state.approvalConditions.projectCondnList
                              .length == 0 &&
                            this.state.approvalConditions.phaseCondnList &&
                            this.state.approvalConditions.phaseCondnList
                              .length == 0 ? (
                              <div></div>
                            ) : (
                              <ApprovalConditions
                                projectlist={
                                  this.state.approvalConditions.projectCondnList
                                }
                                phaseList={
                                  this.state.approvalConditions.phaseCondnList
                                }
                              ></ApprovalConditions>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                  {showHideRestrictions && (
                    <>
                      {this.state.apiErrRestrictions
                        ? this.state.apiErrRestrictions
                        : null}

                      <div className="bigbutton">
                        <div className="add-restrictions-button">
                          <img
                            alt="Critical Warning Icon"
                            className="button-image"
                            src={CriticalIcon}
                          />
                          Add Restrictions{" "}
                          <div style={{ float: "right" }}>
                            <Button
                              style={{ textTransform: "none" }}
                              onClick={() => this.hideComponent("showHideInfo")}
                            >
                              Cancel
                            </Button>
                            <Button
                              style={{
                                color: "#FFFFFF",
                                backgroundColor: "#1176B5",
                                textTransform: "none",
                              }}
                              onClick={this.handleSaveRestrictions}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                      <Grid
                        item
                        xl
                        style={{
                          gridArea: "attributes3",
                          margin: "0px 40px",
                        }}
                      >
                        <div
                          className="btn-tab-container"
                          style={{ borderTop: 0, marginTop: 0 }}
                        >
                          <Grid
                            item
                            xl
                            className="condition-container"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div>
                              <Button
                                onClick={handleAddShow}
                                className="button"
                              >
                                <Add
                                  style={{
                                    color: "#0F5AF0",
                                  }}
                                />{" "}
                                Create custom restriction
                              </Button>
                            </div>
                          </Grid>
                          <div className="tab-view-container">
                            <Button
                              className={
                                this.state.activeTab == "pre"
                                  ? "tab-title active-tab"
                                  : "tab-title"
                              }
                              onClick={() => changeTab("pre")}
                            >
                              Pre-canned (
                              {typeof this.state.formData.preCannedDR !==
                              "undefined"
                                ? this.state.formData.preCannedDR.length
                                : ""}
                              )
                            </Button>
                            <Button
                              className={
                                this.state.activeTab == "add"
                                  ? "tab-title active-tab"
                                  : "tab-title"
                              }
                              onClick={() => changeTab("add")}
                            >
                              Custom (
                              {typeof this.state.formData.customDR !==
                              "undefined"
                                ? this.state.formData.customDR.length +
                                  addedRestrictions.length
                                : addedRestrictions.length}
                              )
                            </Button>
                          </div>
                        </div>
                        <div style={{ height: "200px", overflowY: "auto" }}>
                          {this.state.activeTab == "pre" ? (
                            <ProjectPreRestrictions
                              formData={this.state.formData}
                              name="preCannedDR"
                              setRestrictionsData={setRestrictionsData}
                              isPre
                            />
                          ) : (
                            <>
                              <ProjectPreRestrictions
                                formData={this.state.formData}
                                name="customDR"
                                setRestrictionsData={setRestrictionsData}
                                isPre
                              />
                              <ProjectPreRestrictions
                                formData={this.state.formData}
                                name="addedRestrictions"
                                setRestrictionsData={setRestrictionsData}
                              />
                            </>
                          )}
                        </div>
                        <div className="mendatory-sign">
                          {this.state.restrictionErr}
                        </div>

                        <ProjectPreRestrictionsModal
                          showAdd={this.state.showAdd}
                          handleAddClose={handleAddClose}
                          handleAddClick={handleAddClick}
                          formData={this.state.formData}
                        />
                      </Grid>
                    </>
                  )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section1"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">
                  {" "}
                  Fannie Mae Activity Feed
                </p>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderFmActivity()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion defaultActiveKey="1">
          <a class="anchor" id="section2"></a>
          <Card className="accordion-header-card">
            <Card.Header className="accordion-header-space">
              <ContextAwareToggle eventKey={this.state.collapseKey}>
                <p className="accordion-header-title">
                  Construction/Marketing Phases
                </p>
              </ContextAwareToggle>
              {isProjectStandardRole() &&
              this.getProjectPhaseType().phaseType === "Legal" &&
              this.state.project.projectType === "New Condo" ? (
                <Link
                  to={`/add-child-phase/${this.state.projectId}/${this.state.phaseId}`}
                >
                  <button
                    className="add-phase-button"
                    onClick={this.handleClick}
                  >
                    <Add
                      className="edit-profile-icon"
                      style={{ marginRight: "5px", marginBottom: "4px" }}
                    />
                    Add Child Phase
                  </button>
                </Link>
              ) : (
                ""
              )}
            </Card.Header>
            <Accordion.Collapse eventKey={this.state.collapseKey}>
              <Card.Body className="accordion-body-card">
                {this.renderChildPhase()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {isProjectRole() ? (
          <>
            <Accordion defaultActiveKey="1">
              <a class="anchor" id="section3"></a>
              <Card className="accordion-header-card">
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey={this.state.collapseKey}>
                    <p className="accordion-header-title">Attachments</p>
                  </ContextAwareToggle>
                  {this.state.project.projectType === "New Condo" ? (
                    <>
                      <DropzoneModal
                        projectvalue={this.state.projectId}
                        phasevalue={this.state.phaseId}
                        persvalue={this.state.persId}
                        projectType={this.state.project.projectType}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Card.Header>
                <Accordion.Collapse eventKey={this.state.collapseKey}>
                  <Card.Body className="accordion-body-card">
                    {this.renderPdf()}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="1">
              <a class="anchor" id="section4"></a>
              <Card className="accordion-header-card">
                <Card.Header className="accordion-header-space">
                  <ContextAwareToggle eventKey={this.state.collapseKey}>
                    <p className="accordion-header-title">Comments</p>
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={this.state.collapseKey}>
                  <>
                    <Card.Body className="accordion-body-card">
                      {this.renderComments()}
                    </Card.Body>
                    {!isReadonlyRole() &&
                    isProjectStandardRole() &&
                    this.state.project.projectType === "New Condo" ? (
                      <form
                        className="comment-form"
                        onSubmit={this.submitHandler}
                      >
                        <div>
                          {
                            <textarea
                              className="form-comment-input"
                              required
                              maxLength="5000"
                              placeholder="Type your comment here"
                              type="text"
                              name="comments"
                              value={comments}
                              style={{ width: "88.7%" }}
                              cols={40}
                              rows={10}
                              onChange={(e) => {
                                this.changeHandler(e);
                              }}
                            />
                          }
                          <br />
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "5px",
                              float: "right",
                              fontSize: "14px",
                              color: "#787979",
                            }}
                          >{`${this.state.commentCharCount}/5000`}</span>
                        </div>{" "}
                        <br />
                        <button
                          className="form-submit-icon"
                          type="submit"
                          disabled={this.state.disablePostButton}
                        >
                          Post
                          <FaPaperPlane style={{ marginLeft: "5px" }} />
                        </button>
                      </form>
                    ) : null}
                  </>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        ) : (
          <></>
        )}
        {isProjectRole() && this.state.lenderCertificationList.length !== 0 ? (
          <Accordion defaultActiveKey="1">
            <a class="anchor" id="section7"></a>
            <Card className="accordion-header-card">
              <Card.Header className="accordion-header-space">
                <ContextAwareToggle eventKey={this.state.collapseKey}>
                  <p className="accordion-header-title">Lender Certification</p>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={this.state.collapseKey}>
                <Card.Body className="accordion-body-card">
                  {this.renderLenderCertification()}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <></>
        )}
        <ContainerCModal
          showContainerCModal={this.state.showContainerCModal}
          projectData={() => this.getDropDownStatusVal(this.state.project)}
          setShowConainerCModal={(value) => {
            this.setState({
              showContainerCModal: value,
            });
          }}
          details={this.getDetails(this.state.project)}
          getSavedPhaseResponse={this.getSavedPhaseResponse}
          reasonCodes={(this.state.reasonCodes || []).filter(r => r.active)}
        />
        <ConfirmModal
          showConfirmModal={this.state.showConfirmModal}
          setShowConfirmModal={(value) => {
            this.setState({
              showConfirmModal: value,
            });
          }}
          getSaveConfirmation={this.getSaveConfirmation}
        />
      </div>
    );
  }
}
export default Profile;
