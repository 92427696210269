import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Search.css";
import { IoMdDownload } from "react-icons/io";
import {
  Radio,
  Button,
  TextField,
  Select,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { FaLongArrowAltRight, FaTimes, FaSort } from "react-icons/fa";
import UserName from "../Landing/UserName";
import ReactPaginate from "react-paginate";
import {
  CenterFocusStrong,
  Search as SearchIcon,
  SettingsApplicationsRounded,
} from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Cards from "../Cards/Cards";
import { MdSearch } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { isProjectRole, isLenderRole, isTPORole } from "../../redux/actions/userAction";
import AutoComplete from "../SharedComponents/AutoComplete/AutoComplete";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import SearchPhase from "./SearchPhase";
import ApartmentIcon from "../../images/apartment.svg";
import CriticaIcon from "../../images/Critical@2x.svg";
import { withStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getDays } from "../shared/services/SharedService";

const useStyles = makeStyles((theme) => ({
  inputProps: {
    "aria-label": "lender name",
  },
}));

const CustomTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid #E6E8ED",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
})(Tooltip);


const ReasonCodeTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#f7f7f7",
    border: "1px solid #E6E8ED",
    height: "max-content",
    pointerEvents: "auto!important",
    maxHeight: "300px",
    maxWidth: "300px",
    overflow: "scroll",
  },
  arrow: {
    color: "white",
    "&:before": {
      border: "2px solid #E6E8ED",
    },
  },
})(Tooltip);

export const Search = ({ formData, setFormData, nextStep, step }) => {
  const classes = useStyles();
  const [stateData, setStateData] = React.useState("");
  const fetchStateData = React.useCallback(() => {
    axios
      .get(global.REACT_APP_API_ENDPOINT + `/condoMgr/lookUpValues/states`)
      .then((response) => {
        setStateData(response.data);
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchStateData();
  }, [fetchStateData]);
  const stateResult = Object.keys(stateData).map((key) => stateData[key]);
  const stateList = stateResult.map((state) => {
    return { value: state, label: state };
  });

  const [reviewData, setReviewData] = React.useState("");
  const fetchReviewData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/FM_REVIEW_STATUS`
      )
      .then((response) => {
        setReviewData(response.data);
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchReviewData();
  }, [fetchReviewData]);
  const reviewResult = Object.keys(reviewData).map((key) => reviewData[key]);
  const reviewList = reviewResult.map((review) => {
    return { value: review, label: review };
  });

  const [statusData, setStatusData] = React.useState("");
  const fetchStatusData = React.useCallback(() => {
    axios
      .get(
        global.REACT_APP_API_ENDPOINT +
          `/condoMgr/lookUpValues/LNDR_CERT_STATUS`
      )
      .then((response) => {
        setStatusData(response.data);
      })
      .catch((error) => {
        throw Error(error);
      });
  }, []);
  React.useEffect(() => {
    fetchStatusData();
  }, [fetchStatusData]);
  const statusResult = Object.keys(statusData).map((key) => statusData[key]);
  const statusList = statusResult.map((status) => {
    return { value: status, label: status };
  });

  function validateLender(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  const [apiErr, setApiErr] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [total, setTotal] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDocumentsTable, setShowDocumentsTable] = useState(false);

  function toggleDocumentsTable() {
    setShowDocumentsTable(!showDocumentsTable);
  }
  function searchApi(values) {
    setLoading(true);
    setPageNumber(0);
    if (isProjectRole()) {
      const lenderIdP = JSON.parse(sessionStorage.getItem("searchedLenderId"));
      /*const passDataP = JSON.parse(sessionStorage.getItem("passData"));*/

      /*if (lenderIdP === null) {
        var defaultIdP = "";
      } else {
        var defaultIdP = lenderIdP;
      }*/
      axios
        .post(global.REACT_APP_API_ENDPOINT + `/condoMgr/searchByCriteria/`, {
          condoProjectId: values.condoProjectId,
          condoProjectName: values.condoProjectName,
          condoStreet: values.condoStreet,
          condoCity: values.condoCity,
          condoState: values.condoState,
          condoZip: values.condoZip,
          lenderCertStatus: values.lenderCertStatus,
          lenderId: afLenderId,
          fnmaReviewStatus: values.fnmaReviewStatus,
          searchView: "PS",
        })

        .then((response) => {
          if (response.status == 200) {
            setApiErr("");
            setLoading(false);
            setTimeout(() => {
              setExcelData(response.data);
              setSearchData(response.data.cpDetails);
              setTotal(response.data.totalResults);
              setSearching(true);
              setNotFound(false);
              window.scrollTo(0, 600);

              updateToSessionStorage(
                "searchedProjectId",
                values.condoProjectId
              );
              updateToSessionStorage(
                "searchedProjectName",
                values.condoProjectName
              );
              updateToSessionStorage("searchedStreet", values.condoStreet);
              updateToSessionStorage("searchedCity", values.condoCity);
              updateToSessionStorage("searchedState", values.condoState);
              updateToSessionStorage("searchedZip", values.condoZip);
              updateToSessionStorage(
                "searchedLenderCert",
                values.lenderCertStatus
              );
              updateToSessionStorage(
                "searchedLenderId",
                afLenderId /*defaultIdP*/
              );
              updateToSessionStorage(
                "searchedFnmaReview",
                values.fnmaReviewStatus
              );
            }, 0);
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              setApiErr("Internal server error");
              setLoading(false);
              updateToSessionStorage(
                "searchedProjectId",
                values.condoProjectId
              );
              updateToSessionStorage(
                "searchedProjectName",
                values.condoProjectName
              );
              updateToSessionStorage("searchedStreet", values.condoStreet);
              updateToSessionStorage("searchedCity", values.condoCity);
              updateToSessionStorage("searchedState", values.condoState);
              updateToSessionStorage("searchedZip", values.condoZip);
              updateToSessionStorage(
                "searchedLenderCert",
                values.lenderCertStatus
              );
              updateToSessionStorage("searchedLenderId", afLenderId);
              updateToSessionStorage(
                "searchedFnmaReview",
                values.fnmaReviewStatus
              );
            } else {
              setApiErr(response.data.message);
              setLoading(false);
              updateToSessionStorage(
                "searchedProjectId",
                values.condoProjectId
              );
              updateToSessionStorage(
                "searchedProjectName",
                values.condoProjectName
              );
              updateToSessionStorage("searchedStreet", values.condoStreet);
              updateToSessionStorage("searchedCity", values.condoCity);
              updateToSessionStorage("searchedState", values.condoState);
              updateToSessionStorage("searchedZip", values.condoZip);
              updateToSessionStorage(
                "searchedLenderCert",
                values.lenderCertStatus
              );
              updateToSessionStorage("searchedLenderId", afLenderId);
              updateToSessionStorage(
                "searchedFnmaReview",
                values.fnmaReviewStatus
              );
            }
          } else if (response.status == 404) {
            setLoading(false);
            setSearching(true);
            setNotFound(true);
            window.scrollTo(0, 600);

            updateToSessionStorage("searchedProjectId", values.condoProjectId);
            updateToSessionStorage(
              "searchedProjectName",
              values.condoProjectName
            );
            updateToSessionStorage("searchedStreet", values.condoStreet);
            updateToSessionStorage("searchedCity", values.condoCity);
            updateToSessionStorage("searchedState", values.condoState);
            updateToSessionStorage("searchedZip", values.condoZip);
            updateToSessionStorage(
              "searchedLenderCert",
              values.lenderCertStatus
            );
            updateToSessionStorage("searchedLenderId", afLenderId);
            updateToSessionStorage(
              "searchedFnmaReview",
              values.fnmaReviewStatus
            );
          }
        })

        .catch((error) => {
          console.log("certification phase error", error);
          updateToSessionStorage("searchedProjectId", values.condoProjectId);
          updateToSessionStorage(
            "searchedProjectName",
            values.condoProjectName
          );
          updateToSessionStorage("searchedStreet", values.condoStreet);
          updateToSessionStorage("searchedCity", values.condoCity);
          updateToSessionStorage("searchedState", values.condoState);
          updateToSessionStorage("searchedZip", values.condoZip);
          updateToSessionStorage("searchedLenderCert", values.lenderCertStatus);
          updateToSessionStorage("searchedLenderId", afLenderId);
          updateToSessionStorage("searchedFnmaReview", values.fnmaReviewStatus);
        });
    } else if (isLenderRole() || isTPORole()) {
      const lenderId = JSON.parse(sessionStorage.getItem("lenderId"));
      const passData = JSON.parse(sessionStorage.getItem("passData"));

      if (lenderId === null) {
        var defaultId = passData.sellerServicerCoNo;
      } else {
        var defaultId = lenderId;
      }

      axios
        .post(global.REACT_APP_API_ENDPOINT + `/condoMgr/searchByCriteria/`, {
          condoProjectId: values.condoProjectId,
          condoProjectName: values.condoProjectName,
          condoStreet: values.condoStreet,
          condoCity: values.condoCity,
          condoState: values.condoState,
          condoZip: values.condoZip,
          lenderCertStatus: values.lenderCertStatus,
          lenderId: defaultId,
          fnmaReviewStatus: values.fnmaReviewStatus,
          searchView: "Lender",
        })
        .then((response) => {
          if (response.status == 200) {
            setApiErr("");
            setLoading(false);
            setTimeout(() => {
              setExcelData(response.data);
              setSearchData(response.data.cpDetails);
              setTotal(response.data.totalResults);
              setSearching(true);
              setNotFound(false);
              window.scrollTo(0, 600);

              updateToSessionStorage(
                "searchedProjectId",
                values.condoProjectId
              );
              updateToSessionStorage(
                "searchedProjectName",
                values.condoProjectName
              );
              updateToSessionStorage("searchedStreet", values.condoStreet);
              updateToSessionStorage("searchedCity", values.condoCity);
              updateToSessionStorage("searchedState", values.condoState);
              updateToSessionStorage("searchedZip", values.condoZip);
              updateToSessionStorage(
                "searchedLenderCert",
                values.lenderCertStatus
              );
              updateToSessionStorage(
                "searchedFnmaReview",
                values.fnmaReviewStatus
              );
            }, 0);
          } else if (response.data && response.data.message) {
            if (response.data.message == "Internal server error") {
              setApiErr("Internal server error");
              setLoading(false);
              updateToSessionStorage(
                "searchedProjectId",
                values.condoProjectId
              );
              updateToSessionStorage(
                "searchedProjectName",
                values.condoProjectName
              );
              updateToSessionStorage("searchedStreet", values.condoStreet);
              updateToSessionStorage("searchedCity", values.condoCity);
              updateToSessionStorage("searchedState", values.condoState);
              updateToSessionStorage("searchedZip", values.condoZip);
              updateToSessionStorage(
                "searchedLenderCert",
                values.lenderCertStatus
              );
              updateToSessionStorage(
                "searchedFnmaReview",
                values.fnmaReviewStatus
              );
            } else {
              if (
                response &&
                response.data.message ===
                  "At least one search criteria must be specified if Lender Id is selected"
              ) {
                setApiErr("At least one search criteria must be specified");
                setLoading(false);
                updateToSessionStorage(
                  "searchedProjectId",
                  values.condoProjectId
                );
                updateToSessionStorage(
                  "searchedProjectName",
                  values.condoProjectName
                );
                updateToSessionStorage("searchedStreet", values.condoStreet);
                updateToSessionStorage("searchedCity", values.condoCity);
                updateToSessionStorage("searchedState", values.condoState);
                updateToSessionStorage("searchedZip", values.condoZip);
                updateToSessionStorage(
                  "searchedLenderCert",
                  values.lenderCertStatus
                );
                updateToSessionStorage(
                  "searchedFnmaReview",
                  values.fnmaReviewStatus
                );
              } else {
                setApiErr(response.data.message);
                setLoading(false);
                updateToSessionStorage(
                  "searchedProjectId",
                  values.condoProjectId
                );
                updateToSessionStorage(
                  "searchedProjectName",
                  values.condoProjectName
                );
                updateToSessionStorage("searchedStreet", values.condoStreet);
                updateToSessionStorage("searchedCity", values.condoCity);
                updateToSessionStorage("searchedState", values.condoState);
                updateToSessionStorage("searchedZip", values.condoZip);
                updateToSessionStorage(
                  "searchedLenderCert",
                  values.lenderCertStatus
                );
                updateToSessionStorage(
                  "searchedFnmaReview",
                  values.fnmaReviewStatus
                );
              }
            }
          } else if (response.status === 404) {
            setSearching(true);
            setNotFound(true);
            setLoading(false);
            window.scrollTo(0, 600);
            updateToSessionStorage("searchedProjectId", values.condoProjectId);
            updateToSessionStorage(
              "searchedProjectName",
              values.condoProjectName
            );
            updateToSessionStorage("searchedStreet", values.condoStreet);
            updateToSessionStorage("searchedCity", values.condoCity);
            updateToSessionStorage("searchedState", values.condoState);
            updateToSessionStorage("searchedZip", values.condoZip);
            updateToSessionStorage(
              "searchedLenderCert",
              values.lenderCertStatus
            );
            updateToSessionStorage(
              "searchedFnmaReview",
              values.fnmaReviewStatus
            );
          }
        })
        .catch((error) => {
          console.log("certification phase error", error);
          setLoading(false);
          updateToSessionStorage("searchedProjectId", values.condoProjectId);
          updateToSessionStorage(
            "searchedProjectName",
            values.condoProjectName
          );
          updateToSessionStorage("searchedStreet", values.condoStreet);
          updateToSessionStorage("searchedCity", values.condoCity);
          updateToSessionStorage("searchedState", values.condoState);
          updateToSessionStorage("searchedZip", values.condoZip);
          updateToSessionStorage("searchedLenderCert", values.lenderCertStatus);
          updateToSessionStorage("searchedFnmaReview", values.fnmaReviewStatus);
        });
    }
  }

  function updateToSessionStorage(property, value) {
    if (value && value !== "") {
      sessionStorage.setItem(property, value);
    } else {
      if (sessionStorage.getItem(property, value)) {
        sessionStorage.removeItem(property);
      }
    }
  }

  function removeToSessionStorage(property) {
    sessionStorage.removeItem(property);
  }

  function downloadExcel() {
    axios({
      url:
        global.REACT_APP_API_ENDPOINT + `/condoMgr/exportSearchResultsToExcel`,
      method: "POST",
      responseType: "blob",
      data: excelData,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "search_results.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  }

  useEffect(() => {
    allStorage();
    renderedSearch();
  }, []);

  const renderTooltipData = (list) => {
    return (
      <div
        style={{
          lineHeight: 1.5,
          fontSize: 14,
          maxHeight: "max-content",
        }}
      >
        {list && list.length > 0 ? (
          list.map((l) => (
            <div
              style={{
                fontSize: 14,
                fontFamily: "Source Sans Pro Regular",
                lineHeight: 2,
              }}
            >
              {l}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    );
  };

  function renderedSearch() {
    if (
      formData.condoProjectId == "" &&
      formData.condoProjectName == "" &&
      formData.condoState == "" &&
      formData.condoStreet == "" &&
      formData.condoZip == "" &&
      formData.lenderCertStatus == "" &&
      formData.lenderId == "" &&
      formData.fnmaReviewStatus == ""
    ) {
      //do nothing
    } else {
      searchApi(formData);
    }
  }

  function renderEmptyProjectsStaticText() {
    return (
      <div style={{ textAlign: "-webkit-center" }}>
        <img
          className="apartment-logo"
          alt="Apartment Logo"
          src={ApartmentIcon}
          width={80}
          height={80}
        />
        <div className="find-recently-viewed">
          Find recently viewed projects here
        </div>
        <div className="message-body">
          Once you’ve searched some projects, come back here to find it again
          easily.
        </div>
      </div>
    );
  }
  function allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;

    while (i--) {
      if (keys[i].includes("project")) {
        values.push(JSON.parse(localStorage.getItem(keys[i])));
      }
    }
    let value = "";
    value = renderEmptyProjectsStaticText();
    if (values && values.length) {
      value = <Cards list={values} />;
    }

    return value;
  }

  const [pageNumber, setPageNumber] = useState(0);
  const [sortType, setSortType] = useState({
    newProjectType: "asc",
    projectName: "asc",
    street: "asc",
    totalUnits: "asc",
    fnmaStatus: "asc",
    lenderStatus: "asc",
  });

  const onSort = (column) => {
    const sortOrder = sortType[column] === "asc" ? "desc" : "asc";
    const obj = { ...sortType, [column]: sortOrder };
    setSortType(JSON.parse(JSON.stringify(obj)));
    sortPersList(column);
  };

  const sortPersList = (column) => {
    let sortList = [].concat(searchData);
    sortList
      .sort((a, b) => {
        const item1 = a[column].toString().toLowerCase();
        const item2 = b[column].toString().toLowerCase();
        return sortType[column] === "asc"
          ? item1.localeCompare(item2)
          : item2.localeCompare(item1);
      })
      .map((pers) => pers);
    setSearchData(sortList);
  };

  const renderDottedValue = (value='') => {
    if(value && value.length> 50){
      return `${value.substring(0, 50)}...`
    }
    return value
  }


  const [closeAccordion, setCloseAccordion] = useState(false);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const mapData = searchData
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((searchData) => {
      return (
        <div style={{ margin: "19px 0px" }}>
          <a href={`/project/${searchData.projectId}`}>
            <div className="indv-result-container">
              <div className="col-1">
                {searchData.newProjectType === "New Condo" ? (
                  <div className="type-image-container">
                    <div
                      className="type-title-container"
                      style={{ backgroundColor: "#E86A1B" }}
                    >
                      <div className="type-title">
                        {searchData.newProjectType.toUpperCase().slice(0, -6)}{" "}
                        PROJECT
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="type-image-container-est">
                    <div className="type-title-container">
                      <div className="type-title">
                        {searchData.newProjectType.toUpperCase().slice(0, -6)}{" "}
                        PROJECT
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-2">
                <div className="id">
                  Project ID: {searchData.projectId}
                  {searchData.delRestr === true ? (
                    <CustomTooltip title="Delivery Restrictions" placement="top">
                    <img
                    alt="Warning Logo"
                    className="warning-label" /*style={{ marginLeft: "27px"}}*/
                    src={CriticaIcon}
                  /></CustomTooltip>

                  ) : (
                    <></>
                  )}
                </div>
                <div className="name">{searchData.projectName}</div>
              </div>
              <div className="col-3">{searchData.street}
                                      <br></br>
                                      {searchData.city}, {searchData.state} {searchData.zipcode}
              </div>
              <div className="col-4">{searchData.totalUnits}</div>
              <div className="col-5">
                <div className="status">{
                        searchData.fnmaStatus === 'Approved By Fannie Mae' && getDays(searchData.fnmaStatusExpiryDate) <= 120 &&
                        <CustomTooltip title={"Expires in " + getDays(searchData.fnmaStatusExpiryDate) + " days"} placement="bottom"><AccessTimeIcon className="search-clock" /></CustomTooltip>
                      }{searchData.fnmaStatus}</div>
                {searchData.fnmaStatusExpiryDate == null ? (
                  <></>
                ) : (
                  <div className="date">
                      { searchData.fnmaStatus === 'Approved By Fannie Mae' && getDays(searchData.fnmaStatusExpiryDate) <= 120 ? <p className="expire-date">Expires:{searchData.fnmaStatusExpiryDate}</p> : <p>Expires:{searchData.fnmaStatusExpiryDate}</p>}
                    </div>
                )}
                {searchData.fnmaStatus != "Unavailable" ? (
                  <></>
                ) : (
                  <ReasonCodeTooltip
                    title={renderTooltipData(searchData.reasonCodesList)}
                    placement="right"
                    arrow
                    disableFocusListener 
                    disableTouchListener
                    interactive
                >
                  <div className="date">
                    {renderDottedValue(searchData.reasonCodesList.toString())}
                  </div>
                </ReasonCodeTooltip>

                )}
              </div>
              <div className="col-6">{searchData.lenderStatus}</div>
            </div>
          </a>
          <SearchPhase
            projectId={searchData.projectId}
            pageStatus={closeAccordion}
            totalPhases={searchData.totalPhases}
          />
        </div>
      );
    });

  const pageCount = Math.ceil(searchData.length / usersPerPage);

  const changePage = ({ selected }) => {
    setCloseAccordion(true);
    if (showDocumentsTable) {
      setShowDocumentsTable();
    }
    setPageNumber(selected);
  };

  function validateNumNoStr5(value) {
    let error;
    if (value && !/^\d{5}$/i.test(value)) {
      error = "Invalid";
    }
    return error;
  }

  const [lenderError, setLenderError] = useState("");
  const [afLenderId, setAfLenderId] = useState(
    sessionStorage.getItem("searchedLenderId")
  );
  /*if(sessionStorage.getItem('searchedLenderId')==null){
    updateToSessionStorage('searchedLenderId', "");
  }*/
  const [lenderIdAutoSuggestions, setlenderIdAutoSuggestions] = useState([]);
  const searchHandler = (state, selectedOption) => {
    if (selectedOption) {
      setAfLenderId(selectedOption.lenderId);
    } else {
      let x = `${state}`;
      const newState = x.split(",").reduce((a, c, i) => {
        a[`s${i + 1}`] = c;
        return a;
      }, {});
      setAfLenderId(newState.s1);
    }
  };
  const autoCompleteChangeHandler = (flag, event) => {
    const value = flag ? "" : event.target.value;
    setAfLenderId(value);
    if (event.target.value.length >= 3) {
      const condoPayload = value;

      axios
        .get(
          global.REACT_APP_API_ENDPOINT +
            `/lender/searchLenders/${condoPayload}`
        )
        .then((response) => {
          const data = response.data;
          const slicedData = data.slice(0, 10);
          setlenderIdAutoSuggestions(slicedData);
        })
        .catch((error) => {
          setlenderIdAutoSuggestions([]);
        });
    }
  };
  return (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        setFormData({ lenderId: afLenderId ? afLenderId : "" });
        if (isProjectRole()) {
          if (values.lenderCertStatus !== "" && afLenderId === "") {
            setLenderError(
              "Lender Name/ID is required for Lender Certification Status"
            );
          } else {
            setLenderError("");
            searchApi(values);
          }
        } else if (isLenderRole() || isTPORole()) {
          setLenderError("");
          searchApi(values);
        }
      }}
    >
      {({ values, errors, touched, resetForm }) => (
        <Form id="main">
          <div className="landing-container-background">
            <div className="search">
              <div>
                <div className="title">
                  Welcome back, <UserName></UserName>!
                </div>
                <div className="search-grid-container">
                  <div className="sub-title">Search for a Condo Project</div>
                  <div className="search-bar-grid">
                    <div className="row-1">
                      <div style={{ marginRight: "37px" }}>
                        <div className="field-search-label">Project ID</div>
                        <Field
                          name="condoProjectId"
                          as={TextField}
                          color="#FCFCFC"
                          variant="outlined"
                          size="small"
                          type="number"
                          className="project-id-field"
                          inputProps={{
                            "aria-label": "condo project id",
                          }}
                        />
                      </div>
                      <div>
                        <div className="field-search-label">Project Name</div>
                        <Field
                          name="condoProjectName"
                          as={TextField}
                          color="#FCFCFC"
                          variant="outlined"
                          size="small"
                          className="project-name-field"
                          inputProps={{
                            "aria-label": "condo project name",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row-2">
                      <div style={{ marginRight: "37px" }}>
                        <div className="field-search-label">Street</div>
                        <Field
                          name="condoStreet"
                          as={TextField}
                          color="#FCFCFC"
                          variant="outlined"
                          size="small"
                          className="project-street-field"
                          inputProps={{
                            "aria-label": "condo project street",
                          }}
                        />
                      </div>
                      <div style={{ marginRight: "37.5px" }}>
                        <div className="field-search-label">City</div>
                        <Field
                          name="condoCity"
                          as={TextField}
                          color="#FCFCFC"
                          variant="outlined"
                          size="small"
                          className="project-city-field"
                          inputProps={{
                            "aria-label": "condo project city",
                          }}
                        />
                      </div>
                      <div style={{ marginRight: "36px" }}>
                        <div className="field-search-label">State</div>
                        <Field
                          name="condoState"
                          as="select"
                          className="project-state-field"
                          aria-label="condo state"
                        >
                          <option value="">Select</option>
                          {stateList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div>
                        <div className="field-search-label">Zip code</div>
                        <Field
                          name="condoZip"
                          as={TextField}
                          color="#FCFCFC"
                          variant="outlined"
                          size="small"
                          className="project-zip-field"
                          validate={validateNumNoStr5}
                          inputProps={{
                            maxLength: 5,
                            "aria-label": "condo project zip",
                          }}
                          error={touched.condoZip && errors.condoZip}
                          helperText={touched.condoZip && errors.condoZip}
                        />
                      </div>
                    </div>
                    <div className="row-3">
                      <div style={{ marginRight: "37px" }}>
                        <div className="field-search-label">
                          Fannie Mae Review Status
                        </div>
                        <Field
                          name="fnmaReviewStatus"
                          as="select"
                          className="project-review-field"
                          aria-label="fannie mae review status"
                        >
                          <option value="">Select a Status</option>
                          {reviewList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div style={{ marginRight: "35.5px" }}>
                        <div className="field-search-label">
                          Lender Certification Status
                        </div>
                        <Field
                          name="lenderCertStatus"
                          as="select"
                          className="project-certification-field"
                          aria-label="lender certification status"
                        >
                          <option value="">Select a Status</option>
                          {statusList.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div>
                        {isLenderRole() || isTPORole() ? (
                          <div>
                            <button
                              type="reset"
                              className="search-clear-button-lender"
                              onClick={() => {
                                setAfLenderId("");
                                setLenderError("");
                                setApiErr("");
                                removeToSessionStorage("searchedProjectId");
                                values.condoProjectId = "";
                                removeToSessionStorage("searchedProjectName");
                                values.condoProjectName = "";
                                removeToSessionStorage("searchedStreet");
                                values.condoStreet = "";
                                removeToSessionStorage("searchedCity");
                                values.condoCity = "";
                                removeToSessionStorage("searchedState");
                                values.condoState = "";
                                removeToSessionStorage("searchedZip");
                                values.condoZip = "";
                                removeToSessionStorage("searchedLenderCert");
                                values.lenderCertStatus = "";
                                removeToSessionStorage("searchedFnmaReview");
                                values.fnmaReviewStatus = "";
                                resetForm({
                                  values: {
                                    condoProjectId: "",
                                    condoProjectName: "",
                                    condoStreet: "",
                                    condoCity: "",
                                    condoState: "",
                                    condoZip: "",
                                    lenderCertStatus: "",
                                    fnmaReviewStatus: "",
                                  },
                                });
                                console.log(formData);
                              }}
                            >
                              Clear
                            </button>
                            <button
                              type="submit"
                              className="search-field-button-lender"
                            >
                              <SearchIcon
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  paddingBottom: "2px",
                                  marginRight: "4px",
                                }}
                              />
                              Search
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="field-search-label">
                              Lender Name/ID
                            </div>
                            <AutoComplete
                              autoCompleteClassName={"project-lender-field"}
                              options={[...lenderIdAutoSuggestions]}
                              id={"afLenderId"}
                              name="afLenderId"
                              value={afLenderId || ""}
                              changeHandler={autoCompleteChangeHandler}
                              searchHandler={searchHandler}
                              type="lenderId"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {isLenderRole() || isTPORole() ? (
                      <></>
                    ) : (
                      <div className="row-4">
                        <div>
                          <button
                            type="reset"
                            className="search-clear-button"
                            onClick={() => {
                              setAfLenderId("");
                              setLenderError("");
                              setApiErr("");
                              removeToSessionStorage("searchedProjectId");
                              values.condoProjectId = "";
                              removeToSessionStorage("searchedProjectName");
                              values.condoProjectName = "";
                              removeToSessionStorage("searchedStreet");
                              values.condoStreet = "";
                              removeToSessionStorage("searchedCity");
                              values.condoCity = "";
                              removeToSessionStorage("searchedState");
                              values.condoState = "";
                              removeToSessionStorage("searchedZip");
                              values.condoZip = "";
                              removeToSessionStorage("searchedLenderCert");
                              values.lenderCertStatus = "";
                              removeToSessionStorage("searchedLenderId");
                              values.lenderId = "";
                              removeToSessionStorage("searchedFnmaReview");
                              values.fnmaReviewStatus = "";
                              resetForm({
                                values: {
                                  condoProjectId: "",
                                  condoProjectName: "",
                                  condoStreet: "",
                                  condoCity: "",
                                  condoState: "",
                                  condoZip: "",
                                  lenderCertStatus: "",
                                  fnmaReviewStatus: "",
                                  lenderId: "",
                                },
                              });
                              console.log(formData);
                            }}
                          >
                            Clear
                          </button>
                          <button type="submit" className="search-field-button">
                            <SearchIcon
                              style={{
                                width: "18px",
                                height: "18px",
                                paddingBottom: "2px",
                                marginRight: "4px",
                              }}
                            />
                            Search
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="row-5">
                      {apiErr ? apiErr : null}
                      {lenderError}
                    </div>
                  </div>
                </div>
              </div>
              <div className="results">
                {searching ? (
                  <>
                    <div className="results-grid-container">
                      {loading ? (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress
                            style={{ marginTop: "20px", color: "#1176b5" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className="sub-title">
                          Search Results
                          {notFound ? (
                            <></>
                          ) : (
                            <div className="sub-title2">
                              Showing {total} results
                            </div>
                          )}
                        </div>
                        {notFound ? (
                          <></>
                        ) : (
                          <ReactPaginate
                            previousLabel={
                              (pageNumber && (
                                <div>
                                  <ArrowBackIcon className="circle-pagination-arrow" />
                                </div>
                              )) ||
                              ""
                            }
                            nextLabel={
                              pageNumber < pageCount - 1 ? (
                                <div>
                                  <ArrowForwardIcon className="circle-pagination-arrow" />
                                </div>
                              ) : (
                                ""
                              )
                            }
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"top-nav-container"}
                            pageClassName={"top-page"}
                            breakClassName={"top-break"}
                            previousClassName={"top-prev"}
                            nextClassName={"top-next"}
                            disabledClassName={"top-disabled"}
                            activeClassName={"top-active"}
                          />
                        )}
                        {notFound ? (
                          <></>
                        ) : (
                          <div className="download-xlsx">
                            {/*<button
                            className="button"
                            type="button"
                            onClick={downloadExcel}
                          >
                            <IoMdDownload className="icon" /> Download XLSX
                          </button>*/}
                          </div>
                        )}
                      </div>
                      <div>
                        {notFound ? (
                          <div className="not-found-container">
                            <MdSearch className="icon" />
                            <p className="text">No results found</p>
                            {isLenderRole() || isTPORole() ? (
                              <>
                                <p className="wrap-text">
                                  Please update your search criteria
                                  <br />
                                  -OR-
                                  <br />
                                </p>
                                <p className="wrap-text th">
                                  Need to add a new project or phase into CPM?
                                  <br />
                                </p>
                                <p className="wrap-text">
                                  Please
                                  <a href=" https://singlefamily.fanniemae.com/media/document/pdf/cpm-add-or-update-projectphase-job-aid">
                                    {" "}
                                    read our Instructions
                                  </a>
                                  &nbsp;to add a new record into the system.
                                  <br />
                                  Below are the required documents to request
                                  addition of a new project record.
                                </p>
                                {/* TODO: static for now. We will render it from a template */}

                                <div className="table-wrapper">
                                  <div className="row">
                                    <div
                                      className="box col1"
                                      style={{ border: "none" }}
                                    ></div>
                                    <div
                                      className="box col2"
                                      style={{
                                        borderBottom: "none",
                                        width: "340px",
                                        borderRight: "solid 2px black",
                                      }}
                                    >
                                      Certification Existed in previous CPM
                                    </div>

                                    <div
                                      className="box col4"
                                      style={{
                                        borderBottom: "none",
                                        width: "315px",
                                        borderRight: "none",
                                      }}
                                    >
                                      No Certification Existed in previous CPM
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ borderBottom: "solid 1px black" }}
                                  >
                                    <div className="box col1">
                                      Required Documents
                                    </div>
                                    <div className="box col2">
                                      Certification Active*
                                    </div>
                                    <div
                                      className="box col3"
                                      style={{ borderRight: "solid 2px black" }}
                                    >
                                      Certification Expired
                                    </div>
                                    <div className="box col4">New Project</div>
                                    <div className="box col5">
                                      Established Project
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ borderBottom: "solid 1px black" }}
                                  >
                                    <div className="box col1 noBottomBorder">
                                      Project Request Form
                                    </div>
                                    <div className="box col2 noBottomBorder">
                                      <CheckIcon />
                                    </div>
                                    <div className="box col3 noBottomBorder">
                                      <CheckIcon />
                                    </div>
                                    <div className="box col4 noBottomBorder">
                                      <CheckIcon />
                                    </div>
                                    <div className="box col5 noBottomBorder">
                                      <CheckIcon />
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ borderBottom: "solid 1px black" }}
                                  >
                                    <div className="box col1 noBottomBorder">
                                      PDF of previous certification if
                                      available**
                                    </div>
                                    <div className="box col2 noBottomBorder">
                                      <CheckIcon />
                                    </div>
                                    <div className="box col3 noBottomBorder">
                                      <CheckIcon />
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ borderBottom: "solid 1px black" }}
                                  >
                                    <div className="box col1 noBottomBorder">
                                      Preliminary Title or Declaration
                                    </div>
                                    <div className="box col2 noBottomBorder"></div>
                                    <div className="box col3 noBottomBorder"></div>
                                    <div className="box col4 noBottomBorder">
                                      <CheckIcon />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="box col1 noBottomBorder">
                                      <p className="wrapbox auto">
                                        *No action required unless lender wants
                                        to have Project added back into CPM.
                                        <br />
                                        **If PDF of previous certification is
                                        available, Project Request Form not
                                        required.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <p className="sub-text">
                                Please try your search again, or add a project
                                to Condo Go.
                              </p>
                            )}
                            {isLenderRole() || isTPORole() ? (
                              <></>
                            ) : (
                              <Link to={`/addNewProject`}>
                                <button className="not-found-button">
                                  Add New Project
                                </button>
                              </Link>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="sorting-container">
                              <div className="sort-1">
                                Type
                                <FaSort
                                  className="sort-icon"
                                  onClick={() => {
                                    onSort("newProjectType");
                                  }}
                                />
                              </div>
                              <div className="sort-2">
                                Name
                                <FaSort
                                  className="sort-icon"
                                  onClick={() => {
                                    onSort("projectName");
                                  }}
                                />
                              </div>
                              <div className="sort-3">
                                Address
                                <FaSort
                                  className="sort-icon"
                                  onClick={() => {
                                    onSort("street");
                                  }}
                                />
                              </div>
                              <div className="sort-4">
                                Total Units
                                <FaSort
                                  className="sort-icon"
                                  onClick={() => {
                                    onSort("totalUnits");
                                  }}
                                />
                              </div>
                              <div className="sort-5">
                                Fannie Mae Review Status
                                <FaSort
                                  className="sort-icon"
                                  onClick={() => {
                                    onSort("fnmaStatus");
                                  }}
                                />
                              </div>
                              <div className="sort-6">
                                Certification Status
                                <FaSort
                                  className="sort-icon"
                                  onClick={() => {
                                    onSort("lenderStatus");
                                  }}
                                />
                              </div>
                            </div>
                            {mapData}
                            <ReactPaginate
                              previousLabel={
                                (pageNumber && (
                                  <div>
                                    <ArrowBackIcon className="pagination-arrow" />
                                    Previous
                                  </div>
                                )) ||
                                ""
                              }
                              nextLabel={
                                pageNumber < pageCount - 1 ? (
                                  <div>
                                    Next
                                    <ArrowForwardIcon className="pagination-arrow" />
                                  </div>
                                ) : (
                                  ""
                                )
                              }
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"bot-nav-container"}
                              pageClassName={"bot-page"}
                              breakClassName={"bot-break"}
                              previousClassName={"bot-prev"}
                              nextClassName={"bot-next"}
                              disabledClassName={"bot-disabled"}
                              activeClassName={"bot-active"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      {" "}
                      {!notFound && (isLenderRole() || isTPORole()) ? (
                        <div style={{ textAlign: "center" }}>
                          Please review{" "}
                          <span style={{ color: "#0C77BA" }}>
                            <a href="https://singlefamily.fanniemae.com/media/document/pdf/cpm-add-or-update-projectphase-job-aid">
                              {" "}
                              Add/Update Project Instructions
                            </a>
                          </span>{" "}
                          for details on how to add a project to CPM.
                          {showDocumentsTable ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="table-wrapper"
                                style={{ marginLeft: 0 }}
                              >
                                <div className="row">
                                  <div
                                    className="box col1"
                                    style={{ border: "none" }}
                                  ></div>
                                  <div
                                    className="box col2"
                                    style={{
                                      borderBottom: "none",
                                      width: "340px",
                                      borderRight: "solid 2px black",
                                    }}
                                  >
                                    Certification Existed in previous CPM
                                  </div>
                                  <div
                                    className="box col4"
                                    style={{
                                      borderBottom: "none",
                                      width: "315px",
                                      borderRight: "none",
                                    }}
                                  >
                                    No Certification Existed in previous CPM
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ borderBottom: "solid 1px black" }}
                                >
                                  <div className="box col1">
                                    Required Documents
                                  </div>
                                  <div className="box col2">
                                    Certification Active*
                                  </div>
                                  <div
                                    className="box col3"
                                    style={{ borderRight: "solid 2px black" }}
                                  >
                                    Certification Expired
                                  </div>
                                  <div className="box col4">New Project</div>
                                  <div className="box col5">
                                    Established Project
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ borderBottom: "solid 1px black" }}
                                >
                                  <div className="box col1 noBottomBorder">
                                    Project Request Form
                                  </div>
                                  <div className="box col2 noBottomBorder">
                                    <CheckIcon />
                                  </div>
                                  <div className="box col3 noBottomBorder">
                                    <CheckIcon />
                                  </div>
                                  <div className="box col4 noBottomBorder">
                                    <CheckIcon />
                                  </div>
                                  <div className="box col5 noBottomBorder">
                                    <CheckIcon />
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ borderBottom: "solid 1px black" }}
                                >
                                  <div className="box col1 noBottomBorder">
                                    PDF of previous certification if available**
                                  </div>
                                  <div className="box col2 noBottomBorder">
                                    <CheckIcon />
                                  </div>
                                  <div className="box col3 noBottomBorder">
                                    <CheckIcon />
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ borderBottom: "solid 1px black" }}
                                >
                                  <div className="box col1 noBottomBorder">
                                    Preliminary Title or Declaration
                                  </div>
                                  <div className="box col2 noBottomBorder"></div>
                                  <div className="box col3 noBottomBorder"></div>
                                  <div className="box col4 noBottomBorder">
                                    <CheckIcon />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="box col1 noBottomBorder">
                                    <p className="wrapbox auto">
                                      *No action required unless lender wants to
                                      have Project added back into CPM.
                                      <br />
                                      **If PDF of previous certification is
                                      available, Project Request Form not
                                      required.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <div className="results-grid-container">
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <CircularProgress
                          style={{ marginTop: "20px", color: "#1176b5" }}
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="sub-title">Recently Viewed</div>
                    <div style={{ padding: "24px 114px" }}>{allStorage()}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
