import moment from "moment";

export function certificationFormatter(formData) {
  const passData = JSON.parse(sessionStorage.getItem("passData"));
  const lenderId =
  sessionStorage.getItem("lenderId") ||
  passData.sellerServicerCoNo ||
  "12346";
    const certificationData = {
        guideEligibilityAndProjectInfoAndIds: {
          lenderId: lenderId,
          projectId: formData.projectId,
          phaseId: formData.phaseId === 'proj' ? "" : formData.phaseId,
          createdBy: formData.createdBy,
          guideEligibilityAndProjectInfo: {
            geHotelMotelResortInd: formData.geHotelMotelResortInd,
            geTimeshareCount: formData.geTimeshareCount,
            geHouseBoatInd: formData.geHouseBoatInd,
            geMulLivingSpacesInd: formData.geMulLivingSpacesInd,
            geSplitOwnershipInd: formData.geSplitOwnershipInd,
            geNIncIncomeAbovePmtdInd: formData.geNIncIncomeAbovePmtdInd,
            gePerTotIncNonIncIncome: formData.gePerTotIncNonIncIncome,
            geIncomeFromPermittedSource: formData.geIncomeFromPermittedSource,
            geUnpaidCommonExpAsmtsInd: formData.geUnpaidCommonExpAsmtsInd,
            geCareFacilityInd: formData.geCareFacilityInd,
            gePayMemshpOrLeaseInd: formData.gePayMemshpOrLeaseInd,
            getCmplnHOAOwnshpInd: formData.getCmplnHOAOwnshpInd,
            geEnvHazardInd: formData.geEnvHazardInd,
            geRtFtRefslOrReSlRstnsInd: formData.geRtFtRefslOrReSlRstnsInd,
            piYearBuilt: formData.piYearBuilt,
            piConvtdProjInd: formData.piConvtdProjInd,
            piGutRehabInd: formData.piGutRehabInd,
            piYearConvtd: formData.piYearConvtd,
            piAddnPhsOrAnnxInd: formData.piAddnPhsOrAnnxInd,
            piCntlHOATurnOverInd: formData.piCntlHOATurnOverInd,
            piDtTurndOver: formData.piDtTurndOver,
            piCommnAreasCompltInd: formData.piCommnAreasCompltInd,
          },
        },
        projectPresaleAndInvestor: {
          updatedBy: formData.createdBy,
          projectPresaleInvstr: {
            ppActivePendingSpAssmntsInd: formData.ppActivePendingSpAssmntsInd,
            ppDevOwndUntsRentalHoldBackInd: formData.ppDevOwndUntsRentalHoldBackInd,
            ppDevOwnedUnits: formData.ppDevOwnedUnits,
            ppMaintFeesDevOwndCurrentInd: formData.ppMaintFeesDevOwndCurrentInd,
            ppOwnerOccupiedUnits: formData.ppOwnerOccupiedUnits,
            ppPreSaleConveyPercntge: formData.ppPreSaleConveyPercntge,
            ppSecondHomes: formData.ppSecondHomes,
            ppTotalUnits: formData.ppTotalUnits,
            ppUnitsComplete: formData.ppUnitsComplete,
            ppUnitsConveyed: formData.ppUnitsConveyed,
            ppUnitsRented: formData.ppUnitsRented,
          },
        },
        phasePresaleAndInvestor: {
          updatedBy: formData.createdBy,
          phasePresaleInvstr: {
            phpOwnerOccupiedUnits: formData.phpOwnerOccupiedUnits,
            phpPhases: formData.phpPhases,
            phpPhasesAnnexedToProject: formData.phpPhasesAnnexedToProject,
            phpSecondHomes: formData.phpSecondHomes,
            phpUnits: formData.phpUnits,
            phpUnitsComplete: formData.phpUnitsComplete,
            phpUnitsCurrPhaseAndPrPhase: formData.phpUnitsCurrPhaseAndPrPhase,
            phpUnitsForSale: formData.phpUnitsForSale,
            phpUnitsRented: formData.phpUnitsRented,
            phpUnitsSold: formData.phpUnitsSold,
            phpUnitsSoldOwnerOccpSecondHomeRsdn:
              formData.phpUnitsSoldOwnerOccpSecondHomeRsdn,
          },
        },
        newProjectEligibility: {
          updatedBy: formData.createdBy,
          newProjectEligibility: {
            npeAssrArrgReviewedInd: formData.npeAssrArrgReviewedInd,
            npeFinConExcessInd: formData.npeFinConExcessInd,
            npeLglDocsComplyInd: formData.npeLglDocsComplyInd,
            npeMasterAsscInd: formData.npeMasterAsscInd,
            npePayMnthyAssesLndrRevwPlanInd:
              formData.npePayMnthyAssesLndrRevwPlanInd,
          },
        },
        projectOwnership: {
          updatedBy: formData.createdBy,
          projectOwnership: {
            poLeaseComplyInd: formData.poLeaseComplyInd,
            poLeaseholdInd: formData.poLeaseholdInd,
            poOwnersHaveSoleOwnshpInd: formData.poOwnersHaveSoleOwnshpInd,
            poSnglOwnMoreThanOneInd: formData.poSnglOwnMoreThanOneInd,
            poUnitsSnglOwn: formData.poUnitsSnglOwn,
          },
        },
        lcFinancials: {
          updatedBy: formData.createdBy,
          financials: {
            fnoOfSpAssmts: formData.fnoOfSpAssmts,
            fresStudyReqInd: formData.fresStudyReqInd,
            fmetDefInd: formData.fmetDefInd,
            fmetDefElem: formData.fmetDefElem,
            fotherReasonMetDef: formData.fotherReasonMetDef,
            fleakRepairInd: formData.fleakRepairInd,
            funitsHOAExpnPastDue: formData.funitsHOAExpnPastDue,
            fexpDateAnlBudget:formData.fexpDateAnlBudget,
            fbugtAnlIncome: formData.fbugtAnlIncome,
            famtExclItems: formData.famtExclItems,
            fbugtAnlReserves: formData.fbugtAnlReserves,
            fdefdMntInd: formData.fdefdMntInd,
            fsplAssmtsInd: formData.fsplAssmtsInd,
            fttlSpAssmts:formData.fttlSpAssmts,
            fspAssmts: formData.fspAssmts,
            fphyDetInd: formData.fphyDetInd,
            fphyDetEle: formData.fphyDetEle,
            fotherReasonPhyDet: formData.fotherReasonPhyDet,
            finspFailInd: formData.finspFailInd,
            funfundRepInd: formData.funfundRepInd
          },
        },
        lcLitigation: {
          updatedBy: formData.createdBy,
          litigation: {
            lcommerclSpaceInd: formData.lcommerclSpaceInd,
            linsurancCvgInd: formData.linsurancCvgInd,
            llitigationInd: formData.llitigationInd,
            lminrMttrLitgtnInd: formData.lminrMttrLitgtnInd,
            lnonResSqFtg: formData.lnonResSqFtg,
            lsafetyLitgtnInd: formData.lsafetyLitgtnInd,
            lttlSqFtg: formData.lttlSqFtg,
          },
        },
      };
      return certificationData;
  }

  export function getMultiSelectOptions(data) {
    let options = [];
    if (data.length > 0) {
      if (isJsonString(data[0])) {
        data.map(option => {
          options.push(option.label);
        })
      } else {
        options = data;
      }
    }
    return options;
  }

  export function setMultiSelectOptions(data) {
    let options = [];
    if (data.length > 0) {
      data.map(option => {
        options.push({ "label": option, "value": option });
      })
    }
    return options;
  }

  export function page7SetMultiSelectOptions(formData) {
    if (formData.fmetDefElem.length > 0) {
      const fmetDefElem = setMultiSelectOptions(formData.fmetDefElem);
      formData.fmetDefElem = fmetDefElem;
    }
    if (formData.fspAssmts.length > 0) {
      const fspAssmts = [];
      formData.fspAssmts.map(option => {
        option.freasonsSpAssmts = setMultiSelectOptions(option.freasonsSpAssmts);
        fspAssmts.push(option);
      }) 
    }
    if (formData.fphyDetEle.length > 0) {
      const fphyDetEle = setMultiSelectOptions(formData.fphyDetEle);
      formData.fphyDetEle = fphyDetEle;
    }
    return formData;
  }

  export function page7tMultiSelectOptions(formData) {
    if (formData.fmetDefElem.length > 0) {
      const fmetDefElem = getMultiSelectOptions(formData.fmetDefElem);
      formData.fmetDefElem = fmetDefElem;
    }
    if (formData.fspAssmts.length > 0) {
      const fspAssmts = [];
      formData.fspAssmts.map(option => {
        option.freasonsSpAssmts = getMultiSelectOptions(option.freasonsSpAssmts);
        fspAssmts.push(option);
      }) 
    }
    if (formData.fphyDetEle.length > 0) {
      const fphyDetEle = getMultiSelectOptions(formData.fphyDetEle);
      formData.fphyDetEle = fphyDetEle;
    }
    return formData;
  }

  export function isJsonString(str) {
      try {
        var json = JSON.parse(JSON.stringify(str));
        return (typeof json === 'object');
      } catch (e) {
        return false;
      }
  }

  export function inArray(list, key) {
    if (list.length > 0) {
      var length = list.length;
      for(var i = 0; i < length; i++) {
          if(list[i].toLowerCase() == key.toLowerCase())
              return true;
      }
    }
    return false;
  };


  export function inOptionsArray(list, key) {
    let l = getMultiSelectOptions(list);
    return inArray(l,key)
  };

  export function fspAssmtsRuleCheck(list) {
    let rule = "Pass"
    if (list.length > 0) {
      list.map(ass => {
        if (ass.fremediateRepairIndSt === 'Fail') {
          return rule = "Fail"
        }
        if (ass.fnoOfUnitsDelinquentSt === 'Fail') {
          return rule = "Fail"
        }
      });
    }
    return rule;
  };

  export function getIndex(value){
    let index = '';
    switch (value) {
      case 0:
        index = "first";
        break;
      case 1:
        index = 'second';
        break;
      case 2:
        index = 'third';
        break;
      case 3:
        index = 'fourth';
        break;
      case 4:
        index = 'fifth';
        break;
      default:
        index = "";
        break;
    }
    return index;
  };

  export function getDays(dateSent) {
    let now = moment();
    dateSent = moment(dateSent)
    if (dateSent < now) {
      return 121;
    }
    return Math.abs(
      moment(dateSent, 'YYYY-MM-DD')
        .diff(moment(now, 'YYYY-MM-DD'), 'days')
    ) + 1;
  }
